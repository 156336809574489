import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ITopic } from '../interface';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService, FavoriteService, SystemService, UtilService } from '../../shared/services';
import { OurToastrService } from '../../shared/services/our.toastr.service';
declare var jQuery: any;
declare var localStorage:any;
@Component({
  selector: 'app-card-topic',
  styleUrls: ['./card-topic.component.css'],
  templateUrl: './card-topic.html'
})
export class CardTopicComponent implements OnInit, AfterViewInit {
  @Input()
  topic: ITopic;
  @Input() topicsLength;
  public description: string;
  public langChange: Subscription;
  @Input() config: any;
  public rtl: boolean = false;
  constructor(private route: ActivatedRoute, private systemService: SystemService, private auth: AuthService, private toasty: OurToastrService,
    private topicFavoriteService: FavoriteService
  ) {
    this.config = this.route.snapshot.data['appConfig'];
    // console.log('hiiiiiiiiii', this.config);

    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.changeLanguage(language);
    });
  }

  ngOnInit() {
    if (this.topic) {
      const language = window['localStorage']?.getItem('userLang');
      this.changeLanguage(language);
    }
  }

  changeLanguage(language) {
    if (language === 'ar') {
      this.rtl = true;
    } else {
      this.rtl = false;
    }
  }

  ngAfterViewInit() { }

  favorite() {
    if (!this.auth.isLoggedin())
      this.toasty.error('Please loggin to use this feature!');
    else {
      let params = Object.assign(
        {
          topicId: this.topic._id,
          type: 'topic'
        },
        {}
      );
      this.topicFavoriteService
        .favorite(params, 'topic')
        .then(res => {
          this.topic.isFavorite = true;
          this.toasty.success('Added to your favorite topic list successfully!');
        })
        .catch(() => this.toasty.error('Something went wrong, please try again!'));
    }
  }
  unFavorite() {
    if (!this.auth.isLoggedin())
      this.toasty.error('Please loggin to use this feature!');
    else {
      this.topicFavoriteService
        .unFavorite(this.topic._id, 'topic')
        .then(res => {
          this.topic.isFavorite = false;
          this.toasty.success('Deleted from your favorite topic list successfully!');
        })
        .catch(() => this.toasty.error('Something went wrong, please try again!'));
    }
  }




}
