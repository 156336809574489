import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { AuthService, UtilService } from '../../../shared/services';
import { ReviewService } from '../../services/review.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ReviewUpdateComponent } from '../update/update.component';
import { IReview, IFilterReview, IStatsReview } from '../../interface';
@Component({
  selector: 'review-list',
  templateUrl: './list.html'
})
export class ReviewListComponent implements OnInit {
  @Input() options: IFilterReview;
  @Input() canWriteReview: boolean = false;
  @Input() showReviewsOnly: boolean = false;
  @Output() onUpdateUserRating = new EventEmitter<{
    ratingAvg: number,
    totalRating: number,
    ratingScore: number
  }>();
  @Input() stats: IStatsReview = {
    totalRating: 0,
    ratingAvg: 0,
    ratingScore: 0
  };
  @Input() rtl: boolean = false;
  public page: any = 1;
  public pageSize: any = 10;
  public writeReview: boolean = false;
  public reviews: IReview[] = [];
  public total: any = 0;
  public userId: any = '';

  constructor(
    private reviewService: ReviewService,
    private authService: AuthService,
    private modalService: NgbModal,
    private utilService: UtilService
  ) {

  }
  async ngOnInit() {
    if (this.authService.isLoggedin()) {
      this.userId = (await this.authService.getCurrentUser())._id
    }
    this.query();
    // this.reviewService.current(this.options.type, this.options.appointmentId).then(resp => {
    //   if (resp.data && resp.data._id) this.reviews.push(resp.data);
    // });



  }

  query() {
    this.utilService.setLoading(true);
    this.reviewService
      .list(
        Object.assign(
          {
            page: this.page,
            take: this.pageSize,
            userId: this.userId
          },
          this.options,
        )
      )
      .then(res => {
        this.utilService.setLoading(false);
        this.canWriteReview = res.data.canWriteReview && !this.showReviewsOnly;
        if (res.data.appointmentId)
          this.options.appointmentId = res.data.appointmentId;
        this.reviews = res.data.items;

        this.total = res.data.count;
      });
  }

  onRating(event: any) {
    if (event && event.review._id) {
      this.canWriteReview = false;
    }
    //this.stats.totalRating += 1;
    //this.stats.ratingScore += event.review.rating;
    //this.stats.ratingAvg = (this.stats.ratingScore / this.stats.totalRating);

    this.stats = event.statsReview.target;
    this.total++;
    this.reviews.push(event.review);

    if (event.statsReview.user)
      this.onUpdateUserRating.emit(event.statsReview.user);
  }

  update(item: any, index: number) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    const modalRef = this.modalService.open(ReviewUpdateComponent, ngbModalOptions);
    modalRef.componentInstance.reviewId = item._id;
    modalRef.result.then(
      result => {
        if (result.review._id) {
          this.reviews[index] = result.review;


          this.stats = this.options.rateTo ? result.statsReview.user : result.statsReview.target;
          if (result.statsReview.user)
            this.onUpdateUserRating.emit(result.statsReview.user);
        }
      },
      () => { }
    );
  }
}
