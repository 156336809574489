import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

import { BehaviorSubject } from 'rxjs';
import { OurToastrService } from '../../shared/services/our.toastr.service';

@Injectable({ providedIn: "root" })
export class ShoppingCartService {
  _getActiveCart: any;

  cart = new BehaviorSubject<any>(null);

  public cart$ = this.cart.asObservable();

  constructor(private restangular: Restangular, private toasty: OurToastrService) {
    this.loadActiveCart();
  }


  loadActiveCart() {
    this.getActiveCart().then(resp => {
      this.cart.next(resp?.data||resp);
    }).catch(error => {
      this.toasty.error(error.data?.data?.message || error.data?.message || 'Something went wrong, please try again!');

    })
  }
  getActiveCart(): Promise<any> {
    if (!(window['localStorage']?.getItem('isLoggedin') === 'yes'))
    return new Promise(resolve => resolve(null));

    if (this.cart.value) {
      return new Promise(resolve => resolve(this.cart.value));
    }



    if (this._getActiveCart && typeof this._getActiveCart.then === 'function') {
      return this._getActiveCart;
    }

    this._getActiveCart = this.restangular.one('cart', 'activeCart').get().toPromise();
    return this._getActiveCart;
  }
  addItemToCart(data): Promise<any> {
    return this.restangular.all('cart').post(data).toPromise();
  }

  removeItemFromCart(data): Promise<any> {
    return this.restangular.all('cart/remove').post(data).toPromise();
  }



  payment(data): Promise<any> {
    return this.restangular.all('cart/payment').post(data).toPromise();
  }
}
