import { Injectable } from '@angular/core';
import { RestangularModule, Restangular } from 'ngx-restangular';


@Injectable()
export class EnrollService {
  constructor(private restangular: Restangular) { }

  getTutorEnrolledItems(): Promise<any> {
    return this.restangular.one('enroll/studentEnrolledItemsSorted/').get(
      {
        topics: true,
        subjects: true
      }
    ).toPromise();
  }

  getStudentEnrolledItems(parmas): Promise<any> {
    return this.restangular.one('enroll/studentEnrolledItems/').get(parmas).toPromise();
  }


}
