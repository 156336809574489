import { IUser } from '../../../../user/interface';
import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SeoService, AuthService, CouponService } from '../../../../shared/services';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ICourse } from '../../../../course/interface';
import { FormControl, Validators } from '@angular/forms';
import { OurToastrService } from '../../../../shared/services/our.toastr.service';
import { ICoupon, ITopic } from '../../../../topic/interface';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'coupon-model',
  templateUrl: './coupon.model.html'
})
export class CouponModelComponent implements OnInit {
  //Tutor in "solo" only
  @Input() tutor: IUser;
  public target: ICourse | ITopic;
  public targetType: 'course' | 'solo' | 'webinar';
  public tutorId: string;
  public rtl: boolean = false;
  public coupon: ICoupon = {
    name: '',
    code: '',
    type: 'percent',
    value: 0,
    topicId: null,
    tutorId: '',
    expiredDate: '',
    active: true,
    startTime: '',
    limitNumberOfUse: 1,
    targetType: ''
  } as any;

  public time = {
    startTime: {
      year: 0,
      month: 0,
      day: 0
    },
    expiredDate: {
      year: 0,
      month: 0,
      day: 0
    }
  };
  lastTopicMaximumStrength = 0;
  discount = new FormControl(null, [Validators.required, Validators.max(100), Validators.min(1)]);
  limit = new FormControl(null, [Validators.required, Validators.min(1)]);
  public isSubmitted: Boolean = false;
  public type: 'percent' | 'money' = 'percent';

  isMoneyDiscount: boolean;
  targetColumnIdName: 'courseId' | 'topicId' | '' = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toasty: OurToastrService,
    private authService: AuthService,
    private couponService: CouponService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.coupon.tutorId = this.tutorId;
    if (this.target && this.target._id) {
      if (this.targetType == "course")
        this.coupon.courseId = this.target._id;
      if (this.targetType == "webinar")
        this.coupon.topicId = this.target._id;
    }


    const startTime = new Date(this.coupon.startTime);
    const expiredDate = new Date(this.coupon.expiredDate);
    this.time.startTime = {
      year: startTime.getFullYear(),
      month: startTime.getMonth() === 0 ? 12 : startTime.getMonth() + 1,
      day: startTime.getDate()
    };
    this.time.expiredDate = {
      year: expiredDate.getFullYear(),
      month: expiredDate.getMonth() === 0 ? 12 : expiredDate.getMonth() + 1,
      day: expiredDate.getDate()
    };

    this.updateDiscountLimit();
    this.updateDiscountMaxValue();
  }

  selectDate(event, field) {
    const date = `${event.year}-${event.month}-${event.day}`;
    if (
      moment(date, 'YYYY/MM/DD')
        .add(30, 'second')
        .utc()
        .isBefore(moment().set('hour', 0).set('minute', 0).set('second', 0))
    ) {
      this.time[field] = {
        year: 0,
        month: 0,
        day: 0
      };
      this.coupon[field] = '';
      if (field == "startTime")
        return this.toasty.error('Please select an start date greater than or equal to the current date');
      else
        return this.toasty.error('Please select an expiration date greater than or equal to the current date');
    }
    this.coupon[field] = new Date(date).toString();
    if (
      this.coupon.startTime &&
      this.coupon.expiredDate &&
      moment(this.coupon.startTime).isSameOrAfter(moment(this.coupon.expiredDate))
    ) {
      this.time.expiredDate = {
        year: 0,
        month: 0,
        day: 0
      };
      this.coupon.expiredDate = null;
      return this.toasty.error('The expiration date must be greater than the start date');
    }
  }

  percentage() {
    this.type = 'percent';
    this.updateDiscountMaxValue();
  }
  usd() {
    this.type = 'money';
    this.updateDiscountMaxValue();

  }
  submit(frm) {
    this.isSubmitted = true;
    this.coupon.targetType = this.targetType;
    if (this.target?._id)
      this.coupon[this.targetColumnIdName] = this.target?._id;

    if (!frm.valid || !this.coupon.startTime || !this.coupon.expiredDate || this.discount.invalid   || this.limit.invalid) {
      return this.toasty.error('Invalid form, please try again.');
    }

    const data = _.pick(this.coupon, [
      'name',
      'code',
      'type',
      'value',
      'expiredDate',
      'courseId',
      'tutorId',
      'topicId',
      'active',
      'startTime',
      'limitNumberOfUse',
      'targetType'
    ]);



    if (!this.coupon._id) {
      this.couponService.create(data).then(
        resp => {
          this.coupon = resp.data;
          this.toasty.success('Coupon has been created');
          this.activeModal.close(this.coupon);
        },
        err => this.toasty.error(err.data?.data?.message || err.data?.message || err.message || 'Something went wrong!')
      );
    }
    else {
      this.couponService.update(this.coupon._id, data).then(
        () => {
          this.toasty.success('Coupon has been updated');

          this.activeModal.close(this.coupon);
        },
        err => this.toasty.error(err.data?.data?.message || err.data?.message || err.message || 'Something went wrong!')
      );
    }
  }


  /**
   * Update Discount Max Value
   */
  updateDiscountMaxValue() {
    let max = 100; //Default 100%
    if (this.type == "money")
      max = this.targetType == "solo" ? this.tutor.price1On1Class : this.target.price;

    this.discount.setValidators([Validators.required, Validators.max(max), Validators.min(1)]);
    this.discount.updateValueAndValidity();
  }

  updateDiscountLimit() {
    if (this.targetType == "webinar")
      if (this.lastTopicMaximumStrength != this.target.maximumStrength) {
        this.lastTopicMaximumStrength = this.target.maximumStrength

        this.limit.setValidators([Validators.required, Validators.max(this.target.maximumStrength), Validators.min(1)])
        this.limit.updateValueAndValidity();
      }
  }
}
