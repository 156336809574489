import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SeoService, AuthService, SystemService } from '../../../shared/services';
import { IUser } from '../../interface';
import { Subscription } from 'rxjs';
import { OurToastrService } from '../../../shared/services/our.toastr.service';
import salert from 'sweetalert2'
import { TrnaslateService } from '../../../utils/services/trnaslatee.service';
import { Sweetalert2Service } from '../../../utils/services/sweetalert2.service';
import { TutorService } from '../../../tutor/services/tutor.service';

// import  { SweetAlertOptions } from 'sweetalert2'

@Component({
  templateUrl: './schedule.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  public tab: string = 'schedule';
  public tutor: IUser;
  public loading: boolean = false;
  public rtl: boolean = false;
  public langChange: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private seoService: SeoService,
    private toasty: OurToastrService,
    private authService: AuthService,
    private systemService: SystemService,
    private tutorService:TutorService,
    private translateService: TrnaslateService,
    private sweetalert: Sweetalert2Service
  ) {

    seoService.update('My 1 on 1 Classes');
  }

  ngOnInit() {
    this.checkDefaultMeetingUrl();
    this.getTutorGradesAndSubjects();
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.rtl = this.systemService.checkRTL();
    });

    this.rtl = this.systemService.checkRTL();
    this.loading = true;
    this.authService.getCurrentUser().then(resp => {
      if (resp._id) {
        this.tutor = resp;
 
            
      }
      this.loading = false;
    });
  }

  async checkDefaultMeetingUrl() {
    let currentUser = (await this.userService.me()).data;
    if (currentUser.defaultMeetingUrl && currentUser.defaultMeetingUrl != '')
      return
    let action = await this.sweetalert.confirmWarning("Tutor no Default Meeting Link alert", "Ok, go to profile", "No, back to dashboard")
    if (action.isConfirmed)
      return this.router.navigate(["/users", "profile"]);
    else
      return this.router.navigate(["/users", "dashboard"]);
  }

  getTutorGradesAndSubjects() {
    this.tutorService.getTutorGradesAndSubjects()
      .then(resp => {

           //Check If Current Tutor Not Have Subjects
   if (resp.data.subjects.length == 0)
   this.sweetalert.confirmWarning("Tutor no subjects alert", "Ok, go to profile", "No, back to dashboard")
     .then(action => {
       if (action.isConfirmed)
         return this.router.navigate(["/users", "profile"]);
       else
         return this.router.navigate(["/users", "dashboard"]);
     });

      })
      .catch(() => {
        this.loading = false;
        console.log('Something went wrong, please try again!');
      });
  }
  
  onTabSelect(tab: string) {
    this.tab = tab;
  }
}
