import { ICategory } from '../../learn-with-us/interface';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ICourse } from '../interface';
import { Subscription } from 'rxjs';
import { AuthService, FavoriteService, SystemService } from '../../shared/services';
import { OurToastrService } from '../../shared/services/our.toastr.service';
declare var localStorage:any;
@Component({
  selector: 'app-card-course',
  templateUrl: './card-course.html',
  styleUrls: ['./card-course.component.css']
})
export class CardCourseComponent implements OnInit {
  @Input() course: ICourse;
  @Input() coursesLength;

  public category: ICategory;
  public categories: string = '';
  public searchFields: any = {
    categoryIds: ''
  };
  public config: any;
  public rtl: boolean = false;
  public langChange: Subscription;
  constructor(private router: Router, private route: ActivatedRoute, private systemService: SystemService, private auth: AuthService, private toasty: OurToastrService,
    private favoriteService: FavoriteService) {
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.changeLanguage(language);
    });
  }

  ngOnInit() {
    this.config = this.route.snapshot.data['appConfig'];
    if (this.course) {
      const language = window['localStorage']?.getItem('userLang');
      this.changeLanguage(language);
      if (this.course.categories && this.course.categories.length > 0) {
        this.category = this.course.categories[0];
        this.course.categories.forEach(cat => {
          this.categories = this.categories + cat.name + ', ';
        });
        this.categories = this.categories.slice(0, -2);
      }
    }
  }

  clickCategory(catId: any) {
    let categoryIds = [];
    categoryIds.push(catId);
    this.searchFields.categoryIds = categoryIds.join(',');
    this.router.navigate(['/categories'], {
      queryParams: { categoryIds: this.searchFields.categoryIds }
    });
  }

  changeLanguage(language) {
    if (language === 'ar') {
      this.rtl = true;
    } else {
      this.rtl = false;
    }
  }

  favorite() {
    if (!this.auth.isLoggedin()) this.toasty.error(('Please loggin to use this feature!'));
    else {
      let params = Object.assign(
        {
          courseId: this.course._id,
          type: 'course'
        },
        {}
      );
      this.favoriteService
        .favorite(params, 'course')
        .then(res => {
          this.course.isFavorite = true;
          this.toasty.success(('Added to your favorite course list successfully!'));
        })
        .catch(() => this.toasty.error(('Something went wrong, please try again!')));
    }
  }

  unFavorite() {
    if (!this.auth.isLoggedin()) this.toasty.error(('Please loggin to use this feature!'));
    else {
      this.favoriteService
        .unFavorite(this.course._id, 'course')
        .then(res => {
          this.course.isFavorite = false;
          this.toasty.success(('Deleted from your favorite course list successfully!'));
        })
        .catch(() => this.toasty.error(('Something went wrong, please try again!')));
    }
  }

}
