import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { ISection } from '../../../../course/interface';
import { pick } from 'lodash';
import { FormControl, Validators } from '@angular/forms';
import { SystemService } from '../../../../shared/services';
import { OurToastrService } from '../../../../shared/services/our.toastr.service';

@Component({
  selector: 'app-section-form',
  templateUrl: './section-form.html'
})
export class SectionFormComponent implements OnInit {
  @Input() section: ISection = {};
  public submitted: boolean = false;
  ordering = new FormControl(null, [Validators.required, Validators.min(1)]);

  isRTL = this.systemService.checkRTL()

  constructor(public systemService: SystemService, private toasty: OurToastrService, public activeModal: NgbActiveModal) { }

  ngOnInit() { }

  submit(frm: any) {
    this.submitted = true;
    if (!frm.valid || this.ordering.invalid) {
      return this.toasty.error('Please complete the required fields!');
    }
    this.activeModal.close(pick(this.section, ['title', 'description', 'ordering']));
  }
}
