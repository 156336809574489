import { FormControl, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { TutorService } from '../../services/tutor.service';
import { ITutorCertificate } from '../../../user/interface';
import { OurToastrService } from '../../../shared/services/our.toastr.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-certification',
  templateUrl: './add-certification.html'
})
export class AddCetificationComponent implements OnInit {
  @Input() certificate: ITutorCertificate;
  @Input() type: string;
  @Input() tutorId: string;
  @Input() rtl: boolean = false;
  public maxFileSize: number;
  public submitted: boolean = false;
  currentYear = new Date().getFullYear();
  toYear = new FormControl("", [Validators.max(this.currentYear), Validators.min(1900)]);
  fromYear = new FormControl("", [Validators.max(this.currentYear), Validators.min(1900)]);
  ordering = new FormControl("", [Validators.min(1)]);


  public options: Object = {
    placeholderText: 'Enter description',
    charCounterCount: false,
    imageUpload: false
  };
  public mediaOptions: Object;

  constructor(private toasty: OurToastrService, public activeModal: NgbActiveModal, private tutorService: TutorService) {
    this.maxFileSize = environment.maximumFileSizePDF;
  }

  ngOnInit() {
    this.mediaOptions = {
      url: environment.apiBaseUrl + '/media/files',
      fileFieldName: 'file',
      autoUpload: true,
      accept: "image/*,.doc,.docx,.pdf",
      maxFileSize : environment.maximumFileSizePDF,

      onCompleteItem: resp => {
        this.certificate.documentId = resp.data._id;
        this.certificate.document = resp.data;
      }
    };
    if (!this.certificate) {
      this.certificate = {
        title: '',
        description: '',
        fromYear: '',
        toYear: '',
        verified: false,
        documentId: '',
        ordering: 0,
        tutorId: '',
        type: '',
        document: null
      };
    }
  }

  submit(frm: any) {
    this.submitted = true;
    if (!frm.valid || this.ordering.invalid || this.toYear.invalid || this.fromYear.invalid) {
      return //this.toasty.error('Please complete the required fields!');
    }
    // if (!this.certificate.documentId) {
    //   return this.toasty.error('Please upload certificate!');
    // }
    if (this.certificate.toYear < this.certificate.fromYear) {
      return //this.toasty.error('To year must be greater than from year!');
    }
    this.certificate.tutorId = this.tutorId;
    this.certificate.type = this.type;
    const data = _.pick(this.certificate, [
      'title',
      'description',
      'fromYear',
      'toYear',
      'type',
      'documentId',
      'tutorId',
      'verified',
      'ordering'
    ]);
    data.verified=data.verified||false;
    if (this.certificate._id) {
      this.tutorService
        .updateCertificate(this.certificate._id, data)
        .then(resp => {

          this.activeModal.close(Object.assign(this.certificate,resp.data));

          this.submitted = false;
          this.toasty.success('Updated certificate successfully');
        })
        .catch(e => {
          this.toasty.error(e.data?.message||e.message || 'Something went wrong, please try again!');
        });
    } else {
      this.tutorService
        .createCertificate(data)
        .then(resp => {
          this.activeModal.close(Object.assign(this.certificate,resp.data));
          this.submitted = false;
          this.toasty.success('Created certificate successfully!');
        })
        .catch(e => {
          this.toasty.error(e.data?.message||e.message || 'Something went wrong, please try again!');
        });
    }
  }

  /** If Make Any Change In From Year */
  onChangeFromYear() {
    this.toYear.setValidators([Validators.max(this.currentYear), Validators.min(+this.certificate.fromYear)])
    this.toYear.updateValueAndValidity();
  }
}
