import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { AppointmentService, SystemService } from '../../../../shared/services';
import { RequestRefundService } from '../../../../refund/services/request-refund.service';
import { AuthService } from '../../../../shared/services';
import { IMylesson } from '../../../interface';
import { IFilterReview } from '../../../../reviews/interface';
import { Subscription } from 'rxjs';
import { OurToastrService } from '../../../../shared/services/our.toastr.service';
@Component({
  selector: 'app-detail-lesson',
  templateUrl: './detail.html'
})
export class LessonDetailComponent implements OnInit {
  public appointment: IMylesson = {};
  private aId: any;
  public isShowRefundButton: Boolean = false;
  public reason: String = '';
  public submitted: Boolean = false;
  public options: IFilterReview = {
    appointmentId: '',
    topicId: '',
    tutorId: '',
    userId: '',
    type: ''
  };
  public type: any;
  public config: any;
  public rtl: boolean = false;
  public langChange: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toasty: OurToastrService,
    private appointmentService: AppointmentService,
    private refundService: RequestRefundService,
    private authService: AuthService,
    private systemService: SystemService
  ) {
    this.config = this.route.snapshot.data['appConfig'];
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.rtl = this.systemService.checkRTL();
    });
  }

  ngOnInit() {
    this.rtl = this.systemService.checkRTL();
    this.aId = this.route.snapshot.paramMap.get('id');
    this.authService.getCurrentUser().then(resp => {
      this.type = resp.type;
    });
    this.appointmentService
      .findOne(this.aId)
      .then(resp => {
        this.appointment = resp.data;
        if (this.appointment.paid) {
          this.isShowRefundButton = true;
        }
        this.options.appointmentId = this.appointment._id;
        this.options.tutorId = this.appointment.tutor._id;
        this.options.userId = this.appointment.user._id;
        this.options.type = this.appointment.enrollType;
        if (this.options.type === 'webinar') {
          this.options.topicId = this.appointment.topicId;
        }
      })
      .catch(e => 'Something went wrong, please try again');
  }

  request(type) {
    this.submitted = true;
    if (this.reason === '') {
      return this.toasty.error('Please enter reason');
    }
    this.refundService
      .create({
        appointmentId: this.appointment._id,
        reason: this.reason,
        type
      })
      .then(resp => {
        this.toasty.success('Request successfully!');
      })
      .catch(e => this.toasty.error(e.data.message));
  }
}
