import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SeoService, AuthService, CouponService } from '../../../../shared/services';
import * as _ from 'lodash';
import { ICourse } from '../../../../course/interface';
import { OurToastrService } from '../../../../shared/services/our.toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICoupon, ITopic } from '../../../../topic/interface';
import { CouponModelComponent } from '../coupon-model/coupon-model.component';
import { PlatformService } from '../../../platform.service';
@Component({
  selector: 'coupon-list',
  templateUrl: './coupon-list.html'
})
export class CouponListComponent implements OnInit {
  @Input() target: ICourse | ITopic;
  @Input() targetType: 'course' | 'solo' | 'webinar';
  @Input() tutorId: string;
  @Input() rtl: boolean = false;
  @Output() onTabSelect = new EventEmitter();

  loading: boolean = true;
  public total: number = 0;
  public currentPage: number = 1;
  public pageSize: number = 10;
  fromItem: number = 0;
  toItem: number = 0;
  items: ICoupon[] = [];
  targetColumnIdName: 'courseId' | 'topicId' | '' = '';
  config = {} as any;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toasty: OurToastrService,
    private authService: AuthService,
    private couponService: CouponService,
    private modalService: NgbModal,
    private pt: PlatformService
  ) { }

  ngOnInit() {

    this.config = this.route.snapshot.data['appConfig']
    if (this.targetType == "course")
      this.targetColumnIdName = "courseId";
   else   if (this.targetType == "webinar")
      this.targetColumnIdName = "topicId";

    this.getTargetCoupos();
  }


  showCouponModel(coupon = {} as ICoupon) {
    const modalRef = this.modalService.open(CouponModelComponent, { centered: true, backdrop: 'static', size: 'lg', });
    modalRef.componentInstance.target = this.target;
    modalRef.componentInstance.tutorId = this.tutorId;
    modalRef.componentInstance.targetType = this.targetType;
    modalRef.componentInstance.rtl = this.rtl;
    modalRef.componentInstance.coupon = {...modalRef.componentInstance.coupon, ...coupon };
    modalRef.componentInstance.targetColumnIdName = this.targetColumnIdName;

    modalRef.result.then(res => {
      if (coupon._id) {
        coupon = res;
        let oldIndex = this.items.findIndex(c => c._id == coupon._id);
        this.items[oldIndex] = res;
      } else {
        this.items.unshift(res);
        this.total++;

      }
    },
      () => { }
    );
  }

  getTargetCoupos() {
    this.loading = true;
    this.couponService.search({
      page: this.currentPage,
      take: this.pageSize,
      targetType: this.targetType,
      justCoupon:true,
      [this.targetColumnIdName]: this.target?._id
    }).then(resp => {
      this.total = resp.data.count;
      this.items = resp.data.items;
      if (this.currentPage === 1) {
        this.fromItem = this.currentPage;
        this.toItem = this.items.length;
      } else if (this.currentPage > 1) {
        this.fromItem =
          this.currentPage * this.pageSize > this.total
            ? (this.currentPage - 1) * this.pageSize
            : this.currentPage * this.pageSize;
        this.toItem = this.fromItem + this.items.length;
      }
    }).catch(err => this.toasty.error(err.data?.message || err.message || 'Something went wrong!')
    ).finally(() => {
      this.loading = false;
    })


  }


  pageChange() {
            if(this.pt.isBrowser())
 $('html, body').animate({ scrollTop: 0 });
    this.getTargetCoupos();
  }
  onTab(tab: number) {
    this.onTabSelect.emit(tab);
  }
}


