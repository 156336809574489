import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';


@Injectable()
export class CalendarService {
  updateCustomMeetingUrl(data): Promise<any> {
    return this.restangular.all('schedule/updateCustomMeetingUrl').customPUT(data).toPromise();
  }

  constructor(private restangular: Restangular) { }

  search(params: any): Promise<any> {
    return this.restangular.one('schedule').get(params).toPromise();
  }
  search2(params: any): Promise<any> {
    return this.restangular.one('schedule/search').get(params).toPromise();
  }

  create(data: any): Promise<any> {
    return this.restangular.all('schedule').post(data).toPromise();
  }

  update(id: string, data: any): Promise<any> {
    return this.restangular.all('schedule').customPUT(data, id).toPromise();
  }

  delete(id, isDeleteAnyWay = false): Promise<any> {
    return this.restangular.one(`schedule/${id}`, isDeleteAnyWay).customDELETE().toPromise();
  }

  deleteByHash(hash): Promise<any> {
    return this.restangular.one('schedule/remove-by-hash', hash).customDELETE().toPromise();
  }

  checkByHash(hash): Promise<any> {
    return this.restangular.one(`schedule/check-by-hash/${hash}`).post().toPromise();
  }

  checkByTopic(topicId): Promise<any> {
    return this.restangular.one(`schedule/check-by-topic/${topicId}`).post().toPromise();
  }

  deleteNotUsed(): Promise<any> {
    return this.restangular.one(`schedule/remove-not-used`).customDELETE().toPromise();
  }

}
