import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { AuthService, AppointmentService, SeoService, SystemService } from '../../../../shared/services';
import { IUser, IMylesson } from '../../../interface';
import { Subscription } from 'rxjs';
import { TutorService } from '../../../../tutor/services/tutor.service';
import { TrnaslateService } from '../../../../utils/services/trnaslatee.service';
import { PlatformService } from '../../../../utils/platform.service';
import { EnrollService } from '../../../../shared/services/enroll.service';
import { ITopic } from '../../../../topic/interface';
declare var $: any;

@Component({
  selector: 'app-list-schedule',
  templateUrl: './list.html',
  styleUrls: ['./list.component.css']
})
export class ListScheduleComponent implements OnInit {
  public currentUser: IUser;
  public currentPage: number = 1;
  public pageSize: number = 10;
  public searchFields: any = {
    topicId: '',
    subjectId: ''
  };
  public sortOption = {
    sortBy: 'createdAt',
    sortType: 'desc'
  };
  public appointments: IMylesson[] = [];
  public count: number = 0;
  public loading: boolean = false;
  public timeout: any;
  public rtl: boolean = false;
  public langChange: Subscription;
  topics: ITopic[] = [];
  subjects: any[] = [];
  constructor(
    private auth: AuthService,
    private enrollService: EnrollService,
    private appointmentService: AppointmentService,
    private seoService: SeoService,
    private trnaslateService: TrnaslateService,
    private tutorService: TutorService,
    private systemService: SystemService,
    private pt: PlatformService
  ) {
    seoService.update('My Appointments');
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.rtl = this.systemService.checkRTL();
    });
  }
  ngOnInit() {
    this.rtl = this.systemService.checkRTL();
    if (this.auth.isLoggedin()) {
      this.auth.getCurrentUser().then(resp => {
        this.currentUser = resp;
        this.query(true);
      });
    }
    this.getTutorEnrolledItems();
  }



  getTutorEnrolledItems() {
    this.enrollService.getTutorEnrolledItems()
      .then(res => {
        this.topics = res.data.topics;
        this.subjects = res.data.subjects;
      })
  }



  query(isReset) {
    this.loading = true;
    let params = Object.assign(
      {
        page: this.currentPage,
        take: this.pageSize,
        sort: `${this.sortOption.sortBy}`,
        sortType: `${this.sortOption.sortType}`,
        tutorId: this.currentUser._id
      },
      this.searchFields
    );


    this.appointments = [];
    if (isReset) {
      this.count = 0;

    }
    this.appointmentService
      .search(params)
      .then(resp => {
        this.count = resp.data.count;
        this.appointments = resp.data.items;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        alert('Something went wrong, please try again!');
      });
  }

  sortBy(field: string, type: string) {
    this.sortOption.sortBy = field;
    this.sortOption.sortType = type;
    this.query(false);
  }

  onSort(evt) {
    this.sortOption = evt;
    this.query(false);
  }

  pageChange() {
    if (this.pt.isBrowser())
      $('html, body').animate({ scrollTop: 0 });
    this.query(false);
  }

  doSearch(evt) {
    const searchText = evt.target.value; // this is the search text
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(() => {
      this.searchFields.description = searchText;
      this.query(true);
    }, 400);
  }


  topicFilter() {
    this.searchFields.enrollType = (this.searchFields.topicId != '') ? 'webinar' : '';
    this.searchFields.subjectId = '';

    this.query(true);
  }
  soloFilter() {
    this.searchFields.enrollType = (this.searchFields.subjectId != '') ? 'solo' : '';
    this.searchFields.topicId = '';

    this.query(true);
  }
}
