import { Component, OnInit, ViewChild, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SeoService, LanguageService, UtilService, SystemService, SubjectService, CountryService } from '../../../shared/services';
import { UserService } from '../../services/user.service';
import { TutorService } from '../../../tutor/services/tutor.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GradeService } from '../../../tutor/services/grade.service';
import * as _ from 'lodash';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AvatarUploadComponent } from '../../../media/components/avatar-upload/avatar-upload.component';
import { AddCetificationComponent } from '../../../tutor/components/add-certification/add-certification.component';
import { ICountry, IUser } from '../../interface';
import { Subscription } from 'rxjs';
import { TrnaslateService } from '../../../utils/services/trnaslatee.service';
import { environment } from '../../../../environments/environment';
import { OurToastrService } from '../../../shared/services/our.toastr.service';
@Component({
  selector: 'app-profile-update',
  templateUrl: './form.html',
  styleUrls: [
    './form.component.css',
    '../../../../assets/quill/quill.core.css',
    '../../../../assets/quill/quill.bubble.css',
    '../../../../assets/quill/quill.snow.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ProfileUpdateComponent implements OnInit {
  @ViewChild('language') ngSelectComponent: NgSelectComponent;
  public info: IUser;
  public avatarUrl = '';
  public checkAvatar: boolean;
  public isSubmitted = false;
  youtubeIdIsInvalid = false;
  public avatarOptions: any = {};
  private userId: string;
  @Output() afterCancel = new EventEmitter();
  public Editor = ClassicEditor;
  public isEditProfile: boolean = false;
  public isEditDescription: boolean = false;
  public isEditGrade: boolean = false;
  public isEditSubject: boolean = false;
  public isMeetingLink: boolean = false;
  public languages: any;
  public languageNames: any = [];
  public objectLanguage: any = {};

  public gradeSelected: any = [];
  public grades: any;
  public totalUserGrades: number = 0;

  public subjects: any = [];
  public userSubjects: any = [];
  public totalUserSubjects: number = 0;

  public emailInvite: string = '';

  public timezone: any;
  public loading: boolean = false;
  public showChar: number = 500;
  public showMore: boolean = false;
  public _config: any;
  oldBio: string;
  emailInViteFriendSubmited: boolean;
  oldInfo: IUser;
  oldDefaultMeetingUrl: string;
  oldGradesAndSubjects: any = {

  };
  subjectToSelect = [];
  isGradeSubmitted: boolean;
  countries: ICountry[] = [];
  cities = [];
  public quillConfig = {
    magicUrl: true,
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block', 'link'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ font: [] }],
        [{ align: [] }],

        ['clean']
        // ['image']
      ]
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            return true;
          }
        }
      }
    }
  };
  public rtl: boolean = false;
  public langChange: Subscription;
  constructor(
    private router: Router,
    private subjectService: SubjectService,
    private userService: UserService,
    private toasty: OurToastrService,
    private route: ActivatedRoute,
    private seoService: SeoService,
    private tutorService: TutorService,
    private gradeService: GradeService,
    private languageService: LanguageService,
    private utilService: UtilService,
    private modalService: NgbModal,
    private systemService: SystemService,
    private trnaslateService: TrnaslateService,
    private translate: TrnaslateService,
    private countryService: CountryService

  ) {
    this.subjects = route.snapshot.data.subjects;
    this.trnaslateService.appendLableOnItems(this, 'subjects');
    this._config = this.route.snapshot.data['appConfig'];

    seoService.update('Profile');
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.changeLanguage(language);
    });

    this.systemService.languageChangedFromHeader$.subscribe(language => {
      this.info.emailLanguageDefault = language;
      this.translateCountryNames();
    });
  }

  async ngOnInit() {
    this.rtl = this.systemService.checkRTL();
    this.loading = true;
    this.languages = this.languageService.getLang();
    this.objectLanguage = this.languageService.languages;
    this.avatarOptions = {
      url: environment.apiBaseUrl + '/users/avatar',
      fileFieldName: 'avatar',
      onFinish: resp => {
        this.avatarUrl = resp.data.url;
      }
    };
    await this.userService.me().then(resp => {
      this.info = resp.data;
      this.fillSubjectByGrades();

      if (this.info && this.info.bio && this.info.bio.length > this.showChar) {
        this.showMore = true;
      }
      // if (this.info.type === 'tutor') this.queryTutorInfo();
      // this.bio = this.info.bio;
      this.userId = this.info._id;

      this.avatarUrl = resp.data.avatarUrl;
      if (this.avatarUrl !== environment.apiUrl + '/assets/default-avatar.jpg') this.checkAvatar = true;
      this.queryCities();
    });
    await this.gradeService
      .search({
        take: 100,
        sort: 'ordering',
        sortType: 'asc'
      })
      .then(resp => {
        this.grades = resp.data.items;
        this.trnaslateService.appendLableOnItems(this, 'grades');
      });

    this.queryCountries();
    this.mapGradeName(this.info.grades);
    this.mapLanguageName(this.info.languages);
    this.loading = false;
  }

  changeLanguage(language) {
    if (language === 'ar') {
      this.rtl = true;
    } else {
      this.rtl = false;
    }
  }

  mapGradeName(gradeKeys: any) {
    this.grades.forEach(key => {
      if (gradeKeys.indexOf(key.id) > -1) {
        this.gradeSelected.push(key);
      }
    });

    this.totalUserGrades = this.gradeSelected.length;
  }
  mapLanguageName(languageKeys: any) {
    languageKeys.forEach(key => {
      this.languageNames.push(this.objectLanguage[key]);
    });
  }

  changeTimezone(event) {
    if (event === 'Asia/Saigon') {
      this.info.timezone = 'Asia/Ho_Chi_Minh';
    } else {
      this.info.timezone = event;
    }
  }

  submit(frm: any, number: any, isSubmitForm: boolean = true) {
    if (isSubmitForm) {
      this.isSubmitted = true;
      if (!frm.valid || !this.info.timezone) {
        return this.toasty.error(('Form is invalid, please check again.'));
      }

      //Check From Youtube Id
      this.youtubeIdIsInvalid = false;
      if (this.info.idYoutube) {
        if (new RegExp('(www\.)|(\.com)|(http)|(\/\/)', 'g').test(this.info.idYoutube)) {
          this.youtubeIdIsInvalid = true;
          return this.toasty.error(('Form is invalid, please check again.'));
        }
      }

    }

    if (this.info.type === 'tutor') {
      const data = _.pick(this.info, [
        'name',
        'username',
        //'subjectIds',
        'email',
        'address',
        'phoneNumber',
        //  'grades',
        'languages',
        'password',
        'timezone',
        'gender',
        'zipCode',
        'countryId',
        'city',
        'price1On1Class',
        'idYoutube',
        'emailLanguageDefault',
        'isAllowStudentBookingFree',
        'paypalEmailId'
      ]);

      // if (data.subjectIds.length === 0) {
      //   return this.toasty.error('Veuillez sélectionner au moins un sujet avant de mettre à jour votre profil!');
      // }
      // if (number === 1 && data.grades.length === 0) {
      //   return this.toasty.error("S'il vous plaît sélectionner les grades");
      // }




      this.tutorService
        .update(data)
        .then(resp => {
          this.info = _.merge(resp.data, this.info);
          this.info.subjects = [];
          //this.userSubjects = [];
          // if (this.info.subjectIds) {
          //   this.subjects.forEach(sub => {
          //     if (this.info.subjectIds.indexOf(sub._id) > -1) {
          //       this.info.subjects.push(sub);
          //       this.userSubjects.push(sub);
          //     }
          //   });
          // }
          this.systemService.setUserLang(this.info.emailLanguageDefault);
          // this.totalUserSubjects = this.info.subjects.length;
          this.languageNames = [];
          this.mapLanguageName(this.info.languages);
          // this.gradeSelected = [];
          // this.mapGradeName(this.info.grades);
          this.toasty.success(('Updated successfully!'));
          this.utilService.notifyEvent('profileUpdate', this.info);
          this.isEditProfile = false;

        })
        .catch(err => this.toasty.error(`Something went wrong, please try again`));
    }
    if (this.info.type === 'user' || this.info.type === 'student' || this.info.type === 'parent') {
      this.userService
        .updateMe(this.info)
        .then(resp => {
          this.info = _.merge(resp.data, this.info);
          this.systemService.setUserLang(this.info.emailLanguageDefault);

          this.toasty.success(('Updated successfully!'));
          this.utilService.notifyEvent('profileUpdate', this.info);
          this.isEditProfile = false;

        })
        .catch(err => this.toasty.error(err.data.message || err.data.email));
    }
  }


  /**
   * Update Grade And Subjects
   */
  submitGradeAndSubjets(frm) {
    if (!frm.valid) {
      this.isGradeSubmitted = true;
      return this.toasty.error(('Form is invalid, please check again.'));
    }
    this.isGradeSubmitted = false;

    const data = _.pick(this.info, [
      'subjectIds',
      'grades',
    ]);

    this.tutorService
      .updateGradeAndSubjects(data)
      .then(resp => {

        this.info = _.merge(resp.data, this.info);
        this.info.subjects = [];
        this.userSubjects = [];
        if (this.info.subjectIds) {
          this.subjectToSelect.forEach(sub => {
            if (this.info.subjectIds.indexOf(sub._id) > -1) {
              this.info.subjects.push(sub);
              this.userSubjects.push(sub);
            }
          });
        }

        this.totalUserSubjects = this.info.subjects.length;

        this.gradeSelected = [];
        this.mapGradeName(this.info.grades);
        this.isEditGrade = false;
        this.toasty.success(('Updated successfully!'));
        this.utilService.notifyEvent('profileUpdate', this.info);
      })
      .catch(err => this.toasty.error(`Something went wrong, please try again`));
  }

  submitDescription(frm: any, number: any, isSubmitForm: boolean = true, isUpdateDescription: boolean) {
    if (isSubmitForm) {
      if (!frm.valid) {
        return this.toasty.error(('Form is invalid, please check again.'));
      }
    }

    if (this.info.type === 'tutor') {
      const data = _.pick(this.info, [
        'bio',

      ]);
      this.tutorService.updateDescription(data).then(resp => {
        this.info = _.merge(resp.data, this.info);
        this.toasty.success(('Updated successfully!'));
        this.utilService.notifyEvent('profileUpdate', this.info);
        this.isEditDescription = false;

      })
        .catch(err => this.toasty.error((`Something went wrong, please try again`)));
    }
    if (this.info.type === 'user' || this.info.type === 'student' || this.info.type === 'parent') {
      this.userService
        .updateMe(this.info)
        .then(resp => {
          this.info = _.merge(resp.data, this.info);
          this.toasty.success(('Updated successfully!'));
          this.utilService.notifyEvent('profileUpdate', this.info);
          this.isEditDescription = false;

        })
        .catch(err => this.toasty.error(err.data.message || err.data.email));
    }
  }


  changeNotification(notification: any) {
    if (notification === false) {
      this.info.notificationSettings = true;
    }
    if (notification === true) {
      this.info.notificationSettings = false;
    }
    const data = _.pick(this.info, ['notificationSettings']);
    this.userService
      .update(this.userId, data)
      .then(resp => {
        this.info = _.merge(resp.data, this.info);
        if (this.info.notificationSettings === true) {
          this.toasty.success(('Active notification!'));
        }
        if (this.info.notificationSettings === false) {
          this.toasty.success(('Deactive notification!'));
        }
      })
      .catch(err => this.toasty.error(err.data.data.message || err.data.data.email));
  }

  inviteFriend(form) {
    this.emailInViteFriendSubmited = true;
    if (form.invalid) return;
    this.userService.inviteFriend({ email: this.emailInvite }).then(resp => {
      this.emailInViteFriendSubmited = false;

      if (resp.data?.success) {
        this.emailInvite = null;
        return this.toasty.success(('Invited'));
      } else
        this.toasty.error((resp.message || 'Invite fail'));
    }).catch(error => {
      this.toasty.error((error?.data?.message || 'Invite fail'));
    });
  }

  onChangeLanguage(e: any) {
    this.ngSelectComponent.clearAllText = '';
  }

  onChangeGrade(event: any) {
    this.info.grades = [];
    event.forEach(element => {
      this.info.grades.push(element.id);
    });

    this.fillSubjectByGrades();
    // this.submit('', 0, false);
  }

  // onChangeSubject(event: any) {
  //   this.info.subjectIds = [];
  //   event.forEach(element => {
  //     this.info.subjectIds.push(element.id);
  //   });
  //   this.submit('', 0, false);
  // }


  /** Fill Subjects By Grades */
  fillSubjectByGrades() {
    //Reset For Refill Affter Get Subjects From Server
    this.info.subjects = [];
    this.userSubjects = [];
    let removeIds = [];

    //Get Subjects From Server
    this.subjectService.getByGrades(this.info.grades).then(res => {
      this.subjectToSelect = res.data.items;
      this.trnaslateService.appendLableOnItems(this, 'subjectToSelect');

      let subjectIdsTemp = [...this.info.subjectIds];
      this.info.subjectIds = [];
      //Remove Any Subject Selected Not Releted With Any Grade
      subjectIdsTemp.forEach((id, index) => {
        let sub = this.subjectToSelect.find(se => se._id == id);
        if (sub) {
          this.info.subjects.push(sub);
          this.userSubjects.push(sub);
          this.info.subjectIds.push(id)
        }
      });

      //Do The Fellowing To Update NgSelect
      this.info.subjectIds = [...this.info.subjectIds];
      this.info.subjects = [...this.info.subjects];
      this.userSubjects = [...this.userSubjects];
    });

  }

  open() {
    const modalRef = this.modalService.open(AvatarUploadComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.info = this.info;
    modalRef.result.then(
      res => {
        this.afterCancel.emit(res);
        this.info.avatarUrl = res;
        this.checkAvatar = true;
      },
      () => { }
    );
  }

  openCertification(type: string, index = 0, certificate = null) {
    const modalRef = this.modalService.open(AddCetificationComponent, { centered: true, backdrop: 'static' });
    modalRef.result.then(res => {
      if (certificate) {
        //For Update
        this.info[type][index] = res;
      } else {
        //For Add New
        this.info[type].push(res);
      }
    }, () => {
      //On Dismiss

    });

    modalRef.componentInstance.tutorIfd = this.info._id;
    modalRef.componentInstance.certificate = { ...certificate };
    modalRef.componentInstance.type = type || 'education';
    modalRef.componentInstance.rtl = this.rtl;
    modalRef.componentInstance.onChangeFromYear();

  }

  deleteCer(type: string, index: number, certificate = null) {
    if (window.confirm((this.trnaslateService.getValue('Are you sure want to delete this certificate?')))) {
      this.tutorService
        .deleteCertificate(certificate._id)
        .then(resp => {
          this.info[type].splice(index, 1);
          this.toasty.success(('Deleted certificate successfully'));
        })
        .catch(e => {
          this.toasty.error(('Something went wrong, please try again!'));
        });
    }
  }

  deleteAvatar() {
    if (this.checkAvatar) {
      if (window.confirm(this.translate.getValue('Are you sure want to delete your avatar?'))) {
        this.userService
          .deleteAvatar()
          .then(resp => {
            this.info.avatarUrl = environment.apiUrl + '/assets/default-avatar.jpg';
            this.checkAvatar = false;
            //console.log(this.info.avatarUrl);
            this.toasty.success(('Delete avatar successfully'));
          })
          .catch(e => {
            this.toasty.error(('Something went wrong, please try again!'));
          });
      }
    } else {
      this.toasty.error(('No avatar to delete!'));
    }
  }

  /**
   * Switch Discribtion
   */
  switchBio() {

    if (this.isEditDescription) {
      //It Opened I Will Close To Cancle Update
      //Now I'll Reset Discribtion
      this.info.bio = this.oldBio;

    } else {
      //It Closed And I Will Open To Update
      this.oldBio = this.info.bio;

    }
    this.isEditDescription = !this.isEditDescription;

  }

  /**
 * Switch Edit Profile
 */
  switchEditProfile() {
    if (this.isEditProfile) {
      //It Enabled I Will Disaple To Cancle Update
      //Now I'll Reset Info
      this.info = _.merge(this.info, this.oldInfo);
      this.isSubmitted = false;
    } else {
      //It Disapled And I Will Enabled To Update
      this.oldInfo = {
        name: this.info.name,
        gender: this.info.gender,
        email: this.info.email,
        emailLanguageDefault: this.info.emailLanguageDefault,
        phoneNumber: this.info.phoneNumber,
        address: this.info.address,
        idYoutube: this.info.idYoutube,
        price1On1Class: this.info.price1On1Class,
        zipCode: this.info.zipCode,
        paypalEmailId: this.info.paypalEmailId,
        timezone: this.info.timezone,
        notificationSettings: this.info.notificationSettings,
        isAllowStudentBookingFree: this.info.isAllowStudentBookingFree
      } as IUser;
    }
    this.isEditProfile = !this.isEditProfile;

  }

  /** Switch Edit Grades And Subjects */
  switchEditGradesAndSubjects() {
    //Check Is Already Allowed
    if (this.isEditGrade) {
      this.info = {
        ...this.info,
        ... this.oldGradesAndSubjects
      };
      this.userSubjects = this.oldGradesAndSubjects.userSubjects;
    } else {

      this.oldGradesAndSubjects = {
        userSubjects: this.userSubjects,
        subjectIds: this.info.subjectIds,
        subjects: this.info.subjects,
        grades: this.info.grades,
      };
      // this.fillSubjectByGrades();
    }

    this.isGradeSubmitted = false;
    this.isEditGrade = !this.isEditGrade;
  }



  queryCountries() {
    this.countryService.search({ isHasCities: true }).then(resp => {
      this.countries = resp.data.items;
      this.translateCountryNames()
      this.queryCities()
    });
  }
  queryCities() {
    if (this.countries.length) {

      this.cities = this.countries.find(c => c._id == this.info.countryId)?.cities;
      this.cities = [...this.cities]
      //Ng Select Nedd This
      if (this.info?.city)
        this.info.city = this.cities.find(c => c._id == this.info.city._id)
    }
  }

  translateCountryNames() {
    if (!this.countries.length) return;
    console.log(this.translate.getValue('Egypt'))
    this.countries.forEach(co => {
      co.lable = this.trnaslateService.getValue(co.name);
      co.cities.forEach(city => {
        city.lable = this.trnaslateService.getValue(city.name)
      });
      co.cities = [...co.cities];

    });

    this.countries = [...this.countries];
  }



  submitDefaultMeetingUrl(frm: any) {
    if (!frm.valid) {
      return this.toasty.error(('Form is invalid, please check again.'));
    }
    const data = _.pick(this.info, [
      'defaultMeetingUrl',
    ]);
    this.tutorService.updateDefaultMeetingUrl(data).then(resp => {
      this.info = _.merge(resp.data, this.info);
      this.toasty.success(('Updated successfully!'));
      this.utilService.notifyEvent('profileUpdate', this.info);
      this.isMeetingLink = false;

    })
      .catch(err => this.toasty.error((`Something went wrong, please try again`)));
  }

  switchMeetingLink() {

    if (this.isMeetingLink) {
      this.info.defaultMeetingUrl = this.oldDefaultMeetingUrl;
    } else {
      //It Closed And I Will Open To Update
      this.oldDefaultMeetingUrl = this.info.defaultMeetingUrl;

    }
    this.isMeetingLink = !this.isMeetingLink;


  }

}//End Class
