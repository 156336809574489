import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, SeoService, SystemService, UtilService } from './shared/services';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from './utils/platform.service';
import { environment } from '../environments/environment';

declare var $: any;
declare const initGoToTop: any;
declare var localStorage: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'app';
  public config: any;

  private seoChangedSubscription: Subscription;
  public langChange: Subscription;
  public loading: Subscription;
  constructor(
    private router: Router,
    private authService: AuthService,
    private seo: SeoService,
    private translate: TranslateService,
    private systemService: SystemService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private pt: PlatformService
  ) {
    this.seoChangedSubscription = seo.seoChanged$.subscribe(data => {
      if (!data) {
        return;
      }
      this.seo.updateSeo({
        title: data.title,
        keywords: data.meta?.keywords || this.route.snapshot.data?.appConfig?.homeSEO?.keywords,
        description: data.meta?.description || this.route.snapshot.data?.appConfig?.homeSEO?.description
      })
    });

    if (this.pt.isBrowser())
      this.addStyleUrls();
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // if (this.router.url.indexOf('#topic') === -1 && this.router.url.indexOf('#review') === -1) {
        //   $('html, body').animate({ scrollTop: 0 });
        // } else {
        //   $('html, body').animate(
        //     {
        //       scrollTop: this.router.url.indexOf('#topic') > -1 ? $('#topic').offset().top : $('#review').offset().top
        //     },
        //     1000
        //   );
        // }
        this.systemService.setUserLang(window['localStorage']?.getItem('userLang'));
      }
    });
    const defaultLang = 'ar';
    // https://github.com/ngx-translate/core
    translate.setDefaultLang(defaultLang);
    systemService.configs().then(resp => {
      translate.setDefaultLang(resp.i18n.defaultLanguage);
      translate.use(resp.userLang);

      //change favicon
      this.seo.doc.getElementById('favicon').setAttribute('href', resp.siteFavicon);
    });

    if (this.pt.isBrowser())
      this.langChange = this.systemService.languageChanged$.subscribe(data => {
        this.checkRTL(data);
      });

    this.loading = this.utilService.appLoading$.subscribe(loading => {
      if (!loading) {
        this.checkRTL(window['localStorage']?.getItem('userLang'));
      }
    });
  }

  ngOnInit() {
    if (this.authService.isLoggedin()) {
      this.authService.getCurrentUser();
    }
    if (this.pt.isBrowser()) {
      this.checkRTL(window['localStorage']?.getItem('userLang'));
      window['initGoToTop']();
    }
  }

  checkRTL(lang: string) {
    if (lang === 'ar') {
      $('html').attr('dir', 'rtl');
      $('html').attr('lang', lang);
      $('body').attr('dir', 'rtl');
      $('body').attr('lang', lang);
      var elements = [
        {
          class: '.text-left',
          toAdd: 'text-right',
          toRemove: 'text-left'
        },
        { class: '.profile.position-left', toAdd: 'position-right', toRemove: 'position-left' },
        { class: '.input-group-append.position-right', toAdd: 'position-left', toRemove: 'position-right' },
        { class: 'div.btn-float-right', toAdd: 'text-left', toRemove: 'text-right' },
        { class: '.link-profile.position-left', toAdd: 'position-right', toRemove: 'position-left' },
        { class: '.money.text-right', toAdd: 'text-left', toRemove: 'text-right' }
      ];
      elements.map(e => {
        $(e.class)
          .map(function () {
            this.classList.add(e.toAdd);
            this.classList.remove(e.toRemove);
          })
          .get();
      });
    } else {
      $('html').attr('dir', 'ltr');
      $('html').attr('lang', lang);
      $('body').attr('dir', 'ltr');
      $('body').attr('lang', lang);
      var elements = [
        {
          class: '.text-right',
          toAdd: 'text-left',
          toRemove: 'text-right'
        },
        { class: '.profile.position-right', toAdd: 'position-left', toRemove: 'position-right' },
        { class: '.input-group-append.position-left', toAdd: 'position-right', toRemove: 'position-left' },
        { class: 'div.btn-float-right', toAdd: 'text-right', toRemove: 'text-left' }
      ];
      elements.map(e => {
        $(e.class)
          .map(function () {
            this.classList.add(e.toAdd);
            this.classList.remove(e.toRemove);
          })
          .get();
      });
    }

  }

  addStyleUrls() {
    this.seo.doc.head.insertAdjacentHTML("afterbegin",
      `
    <link href="https://fonts.googleapis.com/css2?family=Almarai:wght@300&family=Roboto&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Tajawal:wght@200&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Tajawal&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
    <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet" />
    <link href="https://cdn.datatables.net/v/bs4/dt-1.10.21/datatables.min.css" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;600;700&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Source+Serif+Pro:wght@400;600;700&display=swap" rel="stylesheet" />
    
    <link href="assets/animate/animate.min.css" rel="stylesheet" />
    <link href="assets/toastr/toastr.css" rel="stylesheet" />
    <link href="assets/ngSelect/default.theme.css" rel="stylesheet" />
    <link href="assets/fullcalendar/main.min.css" rel="stylesheet" />
    `);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.seoChangedSubscription.unsubscribe();
  }
}
