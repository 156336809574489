import { CourseService } from './../../../../shared/services/course.service';
import { UserService } from './../../../services/user.service';
import { ITransaction } from './../../../../transactions/interface';
import { Component, OnInit } from '@angular/core';
import { AuthService, SeoService, SystemService } from '../../../../shared/services';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PlatformService } from '../../../../utils/platform.service';
declare var $: any;
@Component({
  templateUrl: './list.html',
  styleUrls: ['./list.component.css']
})
export class CourseTransactionListComponent implements OnInit {
  public transactions: ITransaction[] = [];
  public tutorId: string;
  public page = 1;
  public timeout: any;
  public config: any;
  public pageSize: number = 10;
  public total: any = 10;
  searchFields:any={};

  public sortOption = {
    sortBy: 'createdAt',
    sortType: 'desc'
  };

  public loading: boolean = false;
  public rtl: boolean = false;
  public langChange: Subscription;

  constructor(
    private courseService: CourseService,
    private auth: AuthService,
    private toasty: ToastrService,
    private route:ActivatedRoute,
    private seoService: SeoService,
    private systemService: SystemService,
    private pt: PlatformService
  ) {
    seoService.update('Course Transactions');
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.rtl = this.systemService.checkRTL();
    });
    this.config = this.route.snapshot.data['appConfig'];
  }
  ngOnInit() {

    this.config = this.route.snapshot.data['appConfig'];

    this.rtl = this.systemService.checkRTL();
    this.auth.getCurrentUser().then(resp => {
      this.tutorId = resp._id;
      this.query();
    });
  }

  query() {
    let params = Object.assign(
      {
        userId: this.tutorId,
          page: this.page,
          take: this.pageSize,
          sort: `${this.sortOption.sortBy}`,
          sortType: `${this.sortOption.sortType}`
      },
      this.searchFields
    );

    this.loading = true;
    this.courseService
      .getTransactions(
        this.tutorId,
        params
      ).then(resp => {
        this.transactions = resp.data.items;
        this.total = resp.data.count;
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
        return this.toasty.error('Something went wrong, please try again');
      });
  }

  pageChange() {
            if(this.pt.isBrowser())
 $('html, body').animate({ scrollTop: 0 });
    this.query();
  }

  sortBy(field: string, type: string) {
    this.sortOption.sortBy = field;
    this.sortOption.sortType = type;
    this.query();
  }

  onSort(evt) {
    this.sortOption = evt;
    this.query();
  }

  doSearch(evt) {
    const searchText = evt.target.value; // this is the search text
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(() => {
      this.searchFields.name = searchText;
      this.total = 0;
      this.transactions = [];
      this.query();
    }, 400);
  }
}
