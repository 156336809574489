import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { TrnaslateService } from "../../utils/services/trnaslatee.service";

@Injectable({ providedIn: "root" })
export class OurToastrService {
    constructor(private toasty: ToastrService, private translateService: TrnaslateService) { }

    success(message: string) { this.toasty.success(this.translateService.getValue(message)) }
    successV2(message: string,staticMessage:string) { this.toasty.success(this.translateService.getValue(message)+' '+this.translateService.getValue(staticMessage)) }
    error(message: string) { this.toasty.error(this.translateService.getValue(message)) }
    info(message: string) { this.toasty.info(this.translateService.getValue(message)) }

}
