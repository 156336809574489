import { Injectable } from "@angular/core";
import { TrnaslateService } from "./trnaslatee.service";
import salert from 'sweetalert2'
import { SeoService } from "../../shared/services";

@Injectable({
    providedIn: 'root'
})
export class Sweetalert2Service {
    constructor(private translateService: TrnaslateService, private seo: SeoService) {
        //Add Css For Fisrt Time Only
        this.seo.doc.head.insertAdjacentHTML("beforeend", `<link href="assets/sweetalert2.min.css" rel="stylesheet">`)
    }


    confirmWarning(text, confirmButtonText, cancelButtonText) {
        return salert.fire({
            text: this.translateService.getValue(text),
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.translateService.getValue(confirmButtonText),
            cancelButtonText: this.translateService.getValue(cancelButtonText),
            icon: "warning"
        });
    }

   
    

}//End Class