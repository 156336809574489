import { ICourse } from './../../../course/interface';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, SeoService, FavoriteService, SystemService } from '../../../shared/services';
import { IUser, ISubject } from '../../../user/interface';
import { ITopic } from '../../../topic/interface';
import { Subscription } from 'rxjs';
import { PlatformService } from '../../../utils/platform.service';
import { TrnaslateService } from '../../../utils/services/trnaslatee.service';
declare var $: any;
@Component({
  templateUrl: 'favorite.html',
  styleUrls: ['./favorite.component.css']
})
export class FavoriteComponent implements OnInit {
  public type: string;
  public page: any = 1;
  public pageSize: number = 9;
  public items: any = {
    tutor: [] as IUser[],
    topic: [] as ITopic[],
    course: [] as ICourse[]
  };
  public total: any = 0;
  public sortOption = {
    sortBy: 'createdAt',
    sortType: 'desc'
  };
  public loading: boolean = false;
  public haveResults: boolean = false;
  public rtl: boolean = false;
  public langChange: Subscription;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private seoService: SeoService,
    private authService: AuthService,
    private toasty: ToastrService,
    private translate:TrnaslateService,
    private favoriteService: FavoriteService,
    private systemService: SystemService,
    private pt:PlatformService
  ) {
    this.route.params.subscribe(params => {
      this.reset();
      this.type = params.type;
      this.seoService.update(this.translate.getValue('Favorite '+this.type));
      this.query();
    });
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.rtl = this.systemService.checkRTL();
    });
  }

  ngOnInit() {
    this.rtl = this.systemService.checkRTL();
  }
  query() {
    let params = Object.assign({
      page: this.page,
      take: this.pageSize,
      sort: this.sortOption.sortBy,
      sortType: this.sortOption.sortType
    });

    if (!this.loading) {
      if (this.type) {
        this.loading = true;
        this.favoriteService
          .search(params, this.type)
          .then(resp => {
            if (resp && resp.data && resp.data.items && resp.data.items.length) {
              this.items[this.type] = resp.data.items;
              this.total = resp.data.count;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            alert('Something went wrong, please try again!');
          });
      }
    }
  }
  pageChange() {
            if(this.pt.isBrowser())
 $('html, body').animate({ scrollTop: 0 });
    this.query();
  }

  reset() {
    this.page = 1;
    this.pageSize = 12;
    this.items = {
      tutor: [] as IUser[],
      topic: [] as ITopic[],
      course: [] as ICourse[]
    };
    this.total = 0;
    this.sortOption = {
      sortBy: 'createdAt',
      sortType: 'desc'
    };
    this.loading = false;
    this.haveResults = false;
  }

//On Un Favorite
  onUnFavorite(id) {

  }

}
