import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SeoService, AuthService, CouponService } from '../../../../shared/services';
import { ToastrService } from 'ngx-toastr';
import { OurToastrService } from '../../../../shared/services/our.toastr.service';
import { IApplyCoupon, ICoupon } from '../../../../topic/interface';
@Component({
  selector: 'app-apply-coupon',
  templateUrl: './apply.html'
})
export class ApplyCouponComponent implements OnInit {
  @Input() options: ICoupon|any = {

    topicId: '',
    tutorId: '',
    courseId: '',
    targetType: null
  } as ICoupon;
  @Output() onApply = new EventEmitter<IApplyCoupon>();
  @Input() isDisabled: boolean;
  @Input() inTable: boolean;

  
  public usedCoupon: boolean = false;
  isAllowToUse = true;
  public couponCode: string = '';
  public appliedCoupon: boolean = false;
  constructor(private couponService: CouponService, private toasty: OurToastrService, private authService: AuthService) { }

  ngOnInit() {
  }


  applyCoupon() {
    this.appliedCoupon = false;
    //Check If User Logged In
    if (!this.authService.isLoggedin()) {
      this.cancelApply();
      return this.toasty.error('Please login to use the coupon!');
    }

    if (!this.couponCode) {
      this.cancelApply();
      return this.toasty.error('Please enter coupon code!');
    }

    this.couponService
      .applyCoupon({
        code: this.couponCode || '',
        targetType: this.options.targetType,
        topicId: this.options.topicId || '',
        tutorId: this.options.tutorId || '',
        courseId: this.options.courseId || ''
      })
      .then(resp => {
        if (resp.data) {
          this.appliedCoupon = true;
          this.onApply.emit({
            appliedCoupon: this.appliedCoupon,
            coupon: resp.data
          });
          return this.toasty.success('Applied coupon');
        }
        else {

          this.cancelApply();

          this.toasty.error('Coupon code not available!');
        }
      })
      .catch(err => {
        this.cancelApply();
        this.toasty.error(err.error?.data?.message || err.message || 'Coupon code not available!');
      });
  }



  cancelApply() {
    //Reset Cou
    this.appliedCoupon = false;
    this.onApply.emit({
      appliedCoupon: this.appliedCoupon,
      coupon: null
    });

  }
}
