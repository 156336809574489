import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { TopicService, CategoryService, SystemService } from '../../../../shared/services';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CalendarService } from '../../../../calendar/services/calendar.service';
import { ITopic } from '../../../../topic/interface';
import { ICategory } from '../../../../learn-with-us/interface';
import { Subscription } from 'rxjs';
import { TutorService } from '../../../../tutor/services/tutor.service';
import { TrnaslateService } from '../../../../utils/services/trnaslatee.service';
import { environment } from '../../../../../environments/environment';
import { OurToastrService } from '../../../../shared/services/our.toastr.service';
@Component({
  selector: 'app-topic-update',
  templateUrl: '../form.html'
  ,
  styleUrls: [
    '../../../../../assets/quill/quill.core.css',
    '../../../../../assets/quill/quill.bubble.css',
    '../../../../../assets/quill/quill.snow.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TopicUpdateComponent implements OnInit {
  public maxFileSize: number;
  public tab: string = 'basicInfo';
  public loading: boolean = false;
  public topic: ITopic = {};
  public isSubmitted: Boolean = false;
  public topicId: string;
  public categories: ICategory[] = [];
  public medias: any = [];
  public mediaOptions: any;
  public mainImageOptions: any;
  public mainImageUrl: String = '';
  public Editor = ClassicEditor;
  public hashTopic: any;
  public imageSelected: any[] = [];
  public filesSelected: any[] = [];

  grades = [];
  subjects = [];
  public quillConfig = {
    magicUrl: true,
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block', 'link'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ font: [] }],
        [{ align: [] }],

        ['clean']
        // ['image']
      ]
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            return true;
          }
        }
      }
    }
  };
  public rtl: boolean = false;
  public langChange: Subscription;

  constructor(
    private router: Router,
    private topicService: TopicService,
    private toasty: OurToastrService,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private tutorService:TutorService,
    private trnaslateService:TrnaslateService,
    private calendarService: CalendarService,
    private systemService: SystemService
  ) {
    this.maxFileSize = window['appConfig']?.maximumFileSizeImage;
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.rtl = this.systemService.checkRTL();
    });
  }

  ngOnInit() {
    this.getTutorGradesAndSubjects();
    this.rtl = this.systemService.checkRTL();
    this.loading = true;
    this.topicId = this.route.snapshot.paramMap.get('id');
    this.topicService
      .findOne(this.topicId)
      .then(resp => {
        if (resp.data.media && resp.data.media.length) {
          this.medias = resp.data.media;
        }
        this.mainImageUrl = resp.data.mainImage.thumbUrl;
        this.topic = resp.data;
        this.loading = false;
      })
      .catch(e => {
        this.loading = false;
        this.toasty.error('Something went wrong, please try again!');
      });
    this.mainImageOptions = {
      url: environment.apiBaseUrl + '/media/photos',
      fileFieldName: 'file',
      autoUpload: true,
      maxFileSize : environment.maximumFileSizeImage,
      onCompleteItem: resp => {
        this.topic.mainImageId = resp.data._id;
        this.mainImageUrl = resp.data.thumbUrl;
      },
      onFileSelect: resp => (this.imageSelected = resp),
      accept: 'image/*',
      id: 'image-upload'
    };
    this.mediaOptions = {
      url: environment.apiBaseUrl + '/media/files',
      fileFieldName: 'file',
      autoUpload: true,
      multiple:true,
      maxFileSize : environment.maximumFileSizeImage,
      onCompleteItem: resp => {
        this.topic.mediaIds.push(resp.data._id);
        this.medias.push(resp.data);
      },
      onFileSelect: resp => (this.filesSelected = resp),
      id: 'file-upload'
    };
  }

  removeMedia(i: any) {
    this.topic.mediaIds.splice(i, 1);
    this.medias.splice(i, 1);
  }


  getTutorGradesAndSubjects() {
    this.tutorService.getTutorGradesAndSubjects()
      .then(resp => {
        this.grades = resp.data.grades;
        this.subjects = resp.data.subjects;
        this.trnaslateService.appendLableOnItems(this, 'subjects');
        this.trnaslateService.appendLableOnItems(this, 'grades');

      })
      .catch(() => {
        this.loading = false;
        console.log('Something went wrong, please try again!');
      });
  }


  submit(frm: any) {
    this.isSubmitted = true;
    if (!frm.valid || !this.topic.description) {
      return this.toasty.error('Invalid form, please try again.');
    }
    if (this.topic.price <= 0 && !this.topic.isFree) {
      return this.toasty.error('Price value should be greater than 0');
    }
    if (!this.topic.mainImageId || !this.topic.mediaIds)
      return this.toasty.error("Please upload all documents")

    if (this.topic.description) {
      this.topic.description = this.topic.description?.replace(
        '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
        ''
      );
    }
    this.loading = true;
    this.calendarService.checkByTopic(this.topicId).then(check => {
      if (!check.data.success) {
        this.loading = false;
        return this.toasty.error('Please create schedule for webinar');
      }
      this.topicService
        .update(
          this.topicId,
          _.pick(this.topic, [
            'name',
            'maximumStrength',
            'categoryIds',
            'price',
            'gradeId',
            'subjectId',
            'mediaIds',
            'mainImageId',
            'description',
            'alias',
            'isFree'
          ])
        )
        .then(resp => {
          this.toasty.success('Updated successfuly!');
          this.router.navigate(['/users/topics']);
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.toasty.error((err.data.data && err.data.data.message) || err.data.message || err.data.email);
        });
    });
  }
  onTabSelect(tab: string) {
    this.tab = tab;
  }
}
