import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, UtilService, SystemService } from '../../../shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { UserService } from '../../../user/services/user.service';
import { OurToastrService } from '../../../shared/services/our.toastr.service';
import { PlatformService } from '../../platform.service';
import { ShoppingCartService } from '../../../shopping-cart/service/shopping-cart.service';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.css'],
  templateUrl: './header.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('showMenu', { static: true }) showMenu: ElementRef;
  @ViewChild('menu', { static: true }) menu: ElementRef;
  public appConfig: any;
  public currentUser: any;
  public isHome: Boolean = false;
  public isOpenedMenu = false;
  public userLang: any;
  public languages: any = [];
  public flag: any = `/assets/images/flags/en.svg`;
  public isLoaded: any = false;
  private userLoadedSubscription: Subscription;
  private observableSubscription: Subscription;
  cart: any = {};
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth >= 769) {
      this.removeActiveClass()
    }
  }
  constructor(
    private authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private systemService: SystemService,
    private translate: TranslateService,
    private userService: UserService,
    private toasty: OurToastrService,
    private pt: PlatformService,
    public cartService: ShoppingCartService
  ) {
    this.userLoadedSubscription = authService.userLoaded$.subscribe(data => (this.currentUser = data));
    this.observableSubscription = this.utilService.eventChanged$.subscribe(data => {
      if (data.name === 'profileUpdate') {
        this.currentUser = data.value;
      }
    });
    if (this.pt.isBrowser())
      $(window).scroll(function () {
        const scroll = $(window).scrollTop();
        if (scroll >= 50) {
          $('.header').addClass('scroll');
        } else {
          $('.header').removeClass('scroll');
        }
      });

  }

  ngOnInit() {
    if (this.authService.isLoggedin()) {
      this.authService.getCurrentUser().then(resp => (this.currentUser = resp));
    }
    this.appConfig = this.route.snapshot.data.appConfig;
    this.isHome = this.router.url === '/';

    this.systemService.languageChanged$.subscribe(language => {
      setTimeout(() => {
        this.userLang = language;
        this.translate.use(this.userLang);
        this.flag = `/assets/images/flags/${this.userLang}.svg`;
      }, 10);
    });
    this.systemService.configs().then(resp => {
      this.isLoaded = true;
      this.languages = resp.i18n.languages;
      this.userLang = resp.userLang;
      this.flag = `/assets/images/flags/${this.userLang}.svg`;
      this.appConfig = resp;
    });

    // var prevScrollpos = window.pageYOffset;

    // window.onscroll = function () {
    //   var currentScrollPos = window.pageYOffset;
    //   if (prevScrollpos > currentScrollPos) {
    //     document.getElementById('navbar').style.top = '0';
    //   } else {
    //     document.getElementById('navbar').style.top = '-90px';
    //   }
    //   prevScrollpos = currentScrollPos;
    // };
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.userLoadedSubscription.unsubscribe();
    this.observableSubscription.unsubscribe();
  }

  logout() {
    this.authService.removeToken();
    this.cartService.cart.next(null);

    // this.router.navigate(['/auth/login']);
    window.location.href = '/';
  }

  changeLang(lang: any) {
    // const body = document.body;
    // console.log(body, lang);


    this.userLang = lang;
    this.systemService.setUserLang(this.userLang);
    this.translate.use(this.userLang);
    this.flag = `/assets/images/flags/${this.userLang}.svg`;

    this.systemService.languageChangedFromHeader$.next(lang);
    //Update Language To Current User
    if (this.authService.isLoggedin()) {
      this.userService
        .updateLanguage({ emailLanguageDefault: lang })
        .then(resp => {
          this.toasty.success('Updated language successfully!');
          // this.utilService.notifyEvent('profileUpdate', this.info);
        })
        .catch(err => this.toasty.error(err.data.message || err.data.email));
    }
    // location.reload();
    if (lang == "ar") {
      document.body.setAttribute("dir", 'rtl');
    }
    if (lang == "en") {
      document.body.setAttribute("dir", 'ltr')
    }
  }

  addActiveClass() {
    // console.log('fi', this.menu);

    if (this.menu.nativeElement.classList.contains('active')) {
      this.menu.nativeElement.classList.remove('active')
      this.menu.nativeElement.previousElementSibling.classList.remove('activeOverlay')
    } else {
      this.menu.nativeElement.previousElementSibling.classList.toggle('activeOverlay')
      this.menu.nativeElement.classList.toggle('active')
    }
  }
  removeActiveClass() {
    this.menu.nativeElement.previousElementSibling.classList.remove('activeOverlay')
    this.menu.nativeElement.classList.remove('active')
  }


  remove(targetType, id, name) {
    var data = {
      targetType,
      id
    }
    this.cartService.removeItemFromCart(data).then(resp => {
      this.toasty.successV2(`Remove successfully`,name);

      this.cartService.cart.next(resp.data);
    }).catch(error => {
      this.toasty.error(error.data?.data?.message || error.data?.message || 'Something went wrong, please try again!');
    })
  }
}
