import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from '../../../message/services/message.service';
import { AuthService } from '../../../shared/services';
import { ConversationService } from '../../../message/services/conversation.service';
import { TranslateService } from '@ngx-translate/core';
import { OurToastrService } from '../../../shared/services/our.toastr.service';

@Component({
  selector: 'send-message-btn',
  template: `<button
    class="{{ 'btn ' + buttonClass }}
    send-message-btn
    
    
    "
    translate
    (click)="sendMessage()"
  >
    <i class="far fa-envelope color-white mx-2"></i>
    <span translate>{{ buttonText }}</span>
  </button>`
})
export class SendMessageButtonComponent {
  @Input() recipientId: string;
  @Input() sendTo: string = 'user';
  @Input() buttonText: string = 'Send a Message';
  @Input() buttonClass: string = 'btn-blue';

  constructor(
    private toasty: OurToastrService,
    private modalService: NgbModal,
    private authService: AuthService,
    private conversationService: ConversationService
  ) {}

  sendMessage() {
    if (!this.authService.isLoggedin()) {
      return this.toasty.error('Please login to send message');
    }
    if (this.sendTo === 'user') {
      this.conversationService
        .create(this.recipientId)
        .then(resp => {
          const modalRef = this.modalService.open(MessageMessageModalComponent, {
            backdrop: 'static',
            keyboard: false
          });
          modalRef.componentInstance.conversation = resp.data;
          modalRef.result.then(
            result => {
              if (!result) {
                this.toasty.success('Your message has been sent');
              }
            },
            () => {}
          );
        })
        .catch(() => this.toasty.error('You can not send messages to yourself'));
    } else {
      const modalRef = this.modalService.open(MessageMessageModalComponent, {
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.sendTo = this.sendTo;
      modalRef.result.then(
        result => {
          if (!result) {
            this.toasty.success('Your message has been sent');
          }
        },
        () => {}
      );
    }
  }
}

@Component({
  templateUrl: './send-message-modal.html'
})
export class MessageMessageModalComponent implements OnInit {
  @Input() conversation: any;
  @Input() sendTo: string = 'user';
  public message: any = {
    text: ''
  };
  public submitted: boolean = false;

  constructor(private service: MessageService, public activeModal: NgbActiveModal, private toasty: OurToastrService) {}

  ngOnInit() {}

  submit(frm: any) {
    this.submitted = true;
    if (frm.invalid) {
      return;
    }

    if (!this.message.text) {
      return this.toasty.error('Please enter message');
    }

    if (this.sendTo === 'user') {
      this.service
        .send({
          conversationId: this.conversation._id,
          type: 'text',
          text: this.message.text
        })
        .then(() => this.activeModal.close());
    } else {
      this.service
        .sendToAdmin({
          type: 'text',
          text: this.message.text
        })
        .then(() => this.activeModal.close());
    }
  }
}
