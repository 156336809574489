export * from './config.resolver';
export * from './language.resolver';
export * from './subjects.resolver';
export * from './posts.resolver';
export * from './category.resolver';
export * from './testimonial.resolver';
export * from './slider.resolver';
export * from './topic.resolver';
export * from './topic.home.page.resolver';
export * from './course.resolver';
export * from './course.home.page.resolver';
export * from './starter.resolver';
