import { Injectable } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { SystemService } from '../../shared/services';

@Injectable({
    providedIn: 'root'
})
export class TrnaslateService {

    translate: TranslatePipe;
    constructor(private translateService: TranslateService, private systemService: SystemService) { }
    getValue(key: string = "") {
        if (key == "") return key;
        return this.translateService.instant(key);
    }



    /** Append Lable By Current Language To Show In Select  Or Other  */
    appendLableOnItems(thisObj: any, listName: string) {
        this.updateLableOnItems(thisObj, listName);
        //Append Also When Change Language
        this.systemService.languageChanged$.subscribe(language => {
            this.updateLableOnItems(thisObj, listName, 1000);
        });
    }

    private updateLableOnItems(thisObj: any, listName: string, timeout = 0) {
        setTimeout(() => {

            if (thisObj[listName]) {
                let listAffterUpdate = thisObj[listName].map(item => {
                    item.lable = this.getValue(item.name);
                    return item;
                }) as any[];
                thisObj[listName] = [...listAffterUpdate];
            }
        }, timeout);
    }

}//End Class
