import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

@Injectable()
export class StarterService {
  public items: any = null;
  private _getItems: any;

  constructor(private restangular: Restangular) { }

  getItems(): Promise<any> {
    if (this.items) {
      return Promise.resolve(this.items);
    }


    if (this._getItems && typeof this._getItems.then === 'function') {
      return this._getItems;
    }


    this._getItems = this.restangular
      .one('getHomePageItems')
      .get()
      .toPromise()
      .then(resp => {
        // console.log('resp',resp)
        this.items = resp;
        return this.items;
      });

    return this._getItems;
  }

}
