import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ILecture } from '../../../../course/interface';
import { pick } from 'lodash';
import { FormControl, Validators } from '@angular/forms';
import { OurToastrService } from '../../../../shared/services/our.toastr.service';
import { SystemService } from '../../../../shared/services';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-lecture-form',
  templateUrl: './lecture-form.html',
  styleUrls: ['./lecture-form.component.css']
})
export class LectureFormComponent implements OnInit {
  @Input() lecture: ILecture = {};
  @Input() courseId: string;
  public maxFileSize: number;
  public submitted: boolean = false;
  public videoOptions: Object = {};
  public pdfOptions: Object = {};
  public audioOptions: Object = {};
  public mediaType: string = 'video';
  public mediaOptions: Object = {};
  public uploading: boolean = false;
  isRTL = this.systemService.checkRTL();
  ordering = new FormControl(null, [Validators.required, Validators.min(1)]);

  maximumFileSizeVideo :number;
  maximumFileSizePDF  :number;
  maximumFileSizeAudio :number;
  constructor(
    private systemService: SystemService,
    private toasty: OurToastrService,
    public activeModal: NgbActiveModal
  ) {
    this.maximumFileSizeVideo = environment.maximumFileSizeVideo;
    this.maximumFileSizePDF = environment.maximumFileSizePDF;
    this.maximumFileSizeAudio= environment.maximumFileSizeAudio;
  }

  ngOnInit() {
    if (!this.lecture._id) this.lecture.mediaType = 'video';
    this.videoOptions = {
      url: environment.apiBaseUrl + '/media/courses',
      autoUpload: true,
      maxFileSize : this.maximumFileSizeVideo,

      onCompleteItem: resp => {

        this.lecture.media = resp;
        this.lecture.mediaId = resp.id;
        this.uploading = false;
      },
      id: 'video-upload',
      accept: 'video/*',
      onUploading: resp => (this.uploading = true),
//      onCulclateVideoDuration:duration=>(this.lecture.totalLength=duration)
    };

    this.audioOptions = {
      url: environment.apiBaseUrl + '/media/audios',
      fileFieldName: 'file',
      autoUpload: true,
      maxFileSize : this.maximumFileSizeAudio,
      onCompleteItem: resp => {
        debugger
        this.lecture.media = resp.data;
        this.lecture.mediaId = resp.data._id;
        this.uploading = false;
      },
      id: 'audio-upload',
      accept: 'audio/*',
      onUploading: resp => (this.uploading = true),
     // onCulclateAudioDuration:duration=>(this.lecture.totalLength=duration)
    };

    this.pdfOptions = {
      url: environment.apiBaseUrl + '/media/files',
      autoUpload: true,
      fileFieldName: 'file',
      maxFileSize : this.maximumFileSizePDF,
      onCompleteItem: resp => {
        this.lecture.media = resp.data;
        this.lecture.mediaId = resp.data._id;
        this.uploading = false;
      },
      id: 'pdf-upload',
      accept: '.pdf',
      onUploading: resp => (this.uploading = true)
    };
    if (this.lecture.mediaType) this.mediaType = this.lecture.mediaType;
    this.lecture.courseId = this.courseId;
  }

  submit(frm: any) {
    this.submitted = true;
    if (!frm.valid || this.ordering.invalid || (this.lecture.mediaType === 'pdf' && this.lecture.totalLength < 1)) {
      return this.toasty.error('Please complete the required fields!');
    }
    if (!this.lecture.mediaId) this.toasty.error('Please upload media content for lecture!');
    else
      this.activeModal.close(
        pick(this.lecture, [
          'courseId',
          'sectionId',
          'title',
          'description',
          'ordering',
          'mediaType',
          'mediaId',
          'totalLength'
        ])
      );
  }

  checkMediaType(type: string) {
    this.lecture.mediaType = type;
    this.mediaType = type;
  }
}
