import { Injectable, PlatformRef } from "@angular/core";
import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";
import { PlatformService } from "../../utils/platform.service";

@Injectable({ providedIn: "platform" })
export class OurMissingTranslationHandler implements MissingTranslationHandler {
    static allMissing: string[] = [];
    handle(params: MissingTranslationHandlerParams) {
        if (this.pt.isBrowser())
            if (OurMissingTranslationHandler.allMissing.findIndex(c => c == params.key) == -1) {
                OurMissingTranslationHandler.allMissing.push(params.key);
                console.log(' ========== New Midding Transaction Run showAllMissing() To Show All ',)
            }
    }
    constructor(private pt: PlatformService) {
        window['showAllMissing'] = () => {
            OurMissingTranslationHandler.allMissing.forEach(c => console.log('https://admin.dorosona.com/i18n/addDirect?q=' + c?.replace(/\s/gm, '%20')))
        }
    }
}
