import { SliderService } from './../services/slider.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { PlatformService } from '../../utils/platform.service';


@Injectable()
export class SliderResolver implements Resolve<Observable<any>> {
  constructor(private service: SliderService, private pt: PlatformService) { }

  resolve(): any {

    if (this.pt.isBrowser()) {
      return this.service.getSliders({ take: 50 }).then(resp => resp.data.items);
    }
  }
}
