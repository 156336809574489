import { ToastrService } from 'ngx-toastr';
import { SectionService } from './../../../../shared/services/section.service';
import { CourseService } from './../../../../shared/services/course.service';
import { ActivatedRoute } from '@angular/router';
import { ICourse, ILecture, ISection } from './../../../../course/interface';
import { IMyCourse } from './../../../interface';
import { MyCourseService } from './../../../../shared/services/my-course.service';
import { OnInit, Component } from '@angular/core';
import { IStatsReview } from '../../../../reviews/interface';
import { interval, Subscription } from 'rxjs';
import { LectureService, SystemService } from '../../../../shared/services';
import { sortBy } from 'lodash';
import { TrnaslateService } from '../../../../utils/services/trnaslatee.service';
import { PlatformService } from '../../../../utils/platform.service';
declare var jQuery: any;

@Component({
  templateUrl: './detail.html',
  styleUrls: ['./detail.component.css']
})
export class MyCourseDetailComponent implements OnInit {
  public myCourseId: string;
  public courseId: string;
  public course: ICourse;
  public myCourse: IMyCourse;
  public sections: ISection[];
  public completedPercent: number = 0;
  public shownItem: any = {
    title: this.translateService.getValue('Introduction Video'),
    type: 'video',
    url: ''
  };
  public optionsReview: any = {
    type: 'course',
    courseId: ''
  };
  public statsReview: IStatsReview = {
    ratingAvg: 0,
    ratingScore: 0,
    totalRating: 0
  };
  public indexSection: number = -1;
  public indexLecture: number = -1;
  public _zoomOut: boolean = false;
  public canNext: boolean = true;
  public canPrev: boolean = true;
  public rtl: boolean = false;
  public langChange: Subscription;
  setInterval: any;
  constructor(
    private route: ActivatedRoute,
    private myCourseService: MyCourseService,
    private sectionService: SectionService,
    private toasty: ToastrService,
    private systemService: SystemService,
    private translateService:TrnaslateService,
    private pt: PlatformService,
    private lectureService: LectureService,
  ) {
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.rtl = this.systemService.checkRTL();
      this.changeHtmlDir();
    });
  }

  ngOnInit() {
    this.rtl = this.systemService.checkRTL();
    this.myCourseId = this.route.snapshot.paramMap.get('id');
    this.myCourseService
      .findOne(this.myCourseId)
      .then(resp => {

        this.myCourse = resp.data;
        this.courseId = resp.data.courseId;

        this.optionsReview.courseId = resp.data.courseId;
        this.course = resp.data.course;
        this.course.totalLecture = 0;
        this.course.totalLength = 0;

        this.statsReview = {
          ...this.statsReview,
          ...{
            ratingAvg: this.course.ratingAvg,
            totalRating: this.course.totalRating,
            ratingScore: this.course.ratingScore
          }
        };

        this.shownItem.url = this.course.videoIntroduction.fileUrl;

        this.sectionService
          .search({ courseId: this.courseId, take: 100, sort: 'ordering', sortType: 'asc' })
          .then(resp => {
            if (resp.data) {
              this.sections = resp.data.items;
              this.sections.forEach((section,index)=>{
                section.lectures = sortBy(section.lectures, ['ordering']);
              })
              this.sections.map(item => {
                this.calDuration(item);
                this.course.totalLength += item.duration;
                this.course.totalLecture += item.totalLecture;
              });

if(this.pt.isBrowser())
{

  (function ($) {
                $(document).ready(function () {
                  $('#accordion').on('hide.bs.collapse show.bs.collapse', e => {
                    $(e.target).prev().find('.btn-collapse i:last-child').toggleClass('fa-minus fa-plus');
                  });
                });
              })(jQuery);


            }
              this.completedPercent = Math.ceil(
                (this.myCourse.lectureIdsCompleted.length / this.course.totalLecture) * 100
              );
            }
          })
          .catch(err => {
            this.toasty.error(
              err.data && err.data.data && err.data.data.message
                ? err.data.data.message
                : 'Something went wrong, please try again!'
            );
          });
      })
      .catch(e => 'Something went wrong, please try again');
  }

  returnDuration(seconds: number) {
    if (seconds == 0) return '00:00';
    else if (seconds < 10) return '00:0' + seconds;
    var duration: string = '';
    if (seconds < 60) return '00:' + seconds;
    else {
      var hour, minute, second;
      hour = seconds < 3600 ? 0 : Math.floor(seconds / 3600);
      if (hour > 0) {
        if (hour < 10) hour = '0' + hour;
        duration = hour + ':';
      }
      var remainSecond = seconds - parseInt(hour) * 3600;
      minute = Math.floor(remainSecond / 60) < 10 ? '0' + Math.floor(remainSecond / 60) : Math.floor(remainSecond / 60);
      second = seconds - parseInt(hour) * 3600 - minute * 60;
      if (second < 10) second = '0' + second;
      return duration + minute + ':' + second;
    }
  }
  calDuration(section: ISection) {
    const lectures = section.lectures;
    let duration = 0;
    lectures.forEach(item => {
      if (item.mediaType === 'pdf')
      duration += item.totalLength*60;
      else {
        duration += item.media.duration;
      }
    });
    section.duration = duration;
  }

  returnDurationString(seconds: number) {
    if (seconds == 0) return '0h:0m';
    else {
      var h, m, s: number;
      h = Math.floor(seconds / 3600);
      m = Math.floor((seconds - h * 3600) / 60);
      s = seconds - h * 3600 - m * 60;
      if (h > 0) {
        return h + 'h' + m + 'm';
      } else {
        return m + 'm' + s + 's';
      }
    }
  }

  async showItem(lecture: ILecture, iSection: number, iLecture: number) {
    this.shownItem.title = lecture.title;
    this.shownItem.type = lecture.mediaType;
    this.shownItem.url = lecture.mediaType === 'video' ? await this.getItemUrl(lecture.media.hashName) : lecture.media.fileUrl;
    this.indexLecture = iLecture;
    this.indexSection = iSection;

    if (!this.sections[iSection].lectures[iLecture + 1]) {
      if (!this.sections[iSection + 1] || (this.sections[iSection + 1] && !this.sections[iSection + 1].lectures[0]))
        this.canNext = false;
    } else this.canNext = true;

    if (iSection === 0 && iLecture === 0) this.canPrev = false;
    else this.canPrev = true;

    if (!this.myCourse.lectureIdsCompleted.includes(lecture._id)) {
      this.myCourse.lectureIdsCompleted.push(lecture._id);
      this.completedPercent = Math.ceil((this.myCourse.lectureIdsCompleted.length / this.course.totalLecture) * 100);
      var params = { lectureId: lecture._id };
      this.myCourseService.updateProgress(this.myCourseId, params).catch(err => {
        this.toasty.error(
          err.data && err.data.data && err.data.data.message
            ? err.data.data.message
            : 'Something went wrong, please try again!'
        );
      });
    }

    //To Fix RTL Issue Direction
    if (this.rtl)
      this.setInterval = setInterval(() => {
        document.dir = this.rtl ? 'rtl' : 'ltr';
      }, 0);
  }
  changeHtmlDir() {
    clearInterval(this.setInterval);
    document.dir = this.rtl ? 'rtl' : 'ltr';
  }
  zoomOut() {
    this._zoomOut = !this._zoomOut;
  }

  next(iSection: number, iLecture: number) {
    if (this.sections[iSection].lectures[iLecture + 1]) {
      this.showItem(this.sections[iSection].lectures[iLecture + 1], iSection, iLecture + 1);
    } else {
      if (this.sections[iSection + 1] || this.sections[iSection + 1].lectures[0]) {
        this.showItem(this.sections[iSection + 1].lectures[0], iSection + 1, 0);
      }
    }
  }

  prev(iSection: number, iLecture: number) {
    if (iLecture !== 0) {
      this.showItem(this.sections[iSection].lectures[iLecture - 1], iSection, iLecture - 1);
    } else {
      if (this.sections[iSection - 1]) {
        this.showItem(
          this.sections[iSection - 1].lectures[this.sections[iSection - 1].lectures.length - 1],
          iSection - 1,
          this.sections[iSection - 1].lectures.length - 1
        );
      }
    }
  }

  async getItemUrl(hashName) {
    const resp = await this.lectureService.getMediaUrl(hashName);
    return resp.data;
  }
}
