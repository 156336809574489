import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploadComponent } from './components/file-upload.component';
import { MediaService } from './service';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import {AvatarUploadComponent,NgbdModalComponent} from './components/avatar-upload/avatar-upload.component';
import { OurMissingTranslationHandler } from '../shared/services/our.missing.translation.handler.service';
import { TusUploadComponent } from './components/tus-upload/tus-upload.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule, FileUploadModule, TranslateModule.forChild({
    missingTranslationHandler: {
      provide: MissingTranslationHandler,
      useClass: OurMissingTranslationHandler
    }
  })],
  exports: [FileUploadComponent,AvatarUploadComponent, TusUploadComponent],
  declarations: [FileUploadComponent,AvatarUploadComponent,NgbdModalComponent, TusUploadComponent],
  entryComponents: [],
  providers: [MediaService]
})
export class MediaModule {}
