import { SeoService } from './../../../shared/services/seo.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../shared/services';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public type: any = '';

  tutorCards = [
    {
      title: 'Account Settings',
      description: 'This is content account setting.',
      image: '../../assets/images/tutor/profile.svg',
      url: ['/users/profile'],
      csssClasses: 'bg-color-green'
    },
    // {
    //   title: 'Webinar Topic',
    //   description: 'This is content webinar topic.',
    //   image: '../../assets/images/tutor/webinar-topic.svg',
    //   url: ['/users/topics'],
    //   csssClasses: 'bg-color-dark-green'
    // },
    {
      title: 'Add a New Webinar',
      description: 'This is content add a webinar',
      image: '../../assets/images/tutor/add-new-webinar.svg',
      url: ['/users/topics/create'],
      csssClasses: 'bg-color-blue'
    },
    {
      title: 'My 1 on 1 Appointments',
      description: 'This is content my 1 on 1 appointment and webinar.',
      image: '../../assets/images/tutor/1-on-1-appointment.svg',
      url: ['/users/appointments'],
      csssClasses: 'bg-color-blue'
    },
    {
      title: 'Update My Calendar',
      description: 'This is content update my calendar.',
      image: '../../assets/images/tutor/calendar.svg',
      url: ['/users/schedule'],
      csssClasses: 'bg-color-dark-green'
    },
    {
      title: 'Invite a Friend',
      description: 'This is content invite a friend.',
      image: '../../assets/images/tutor/friend.svg',
      url: ['/users/profile'],
      csssClasses: 'bg-color-orange'
    },
    //{
    //  title: "My Webinar Appointments",
    //  description: "This is content my webinar appointment.",
    //  image: "../../assets/images/tutor/webinar-appointment.svg",
    //  url: ['/users/appointments'],
    //  csssClasses: "bg-color-red"
    //},
    // {
    //  title: "Payouts",
    //  description: "This is content payout.",
    //  image: "../../assets/images/tutor/payouts.svg",
    //  url: ['/users/payout/request'],
    //  csssClasses: "bg-color-purple"
    //},

    {
      title: 'Add My Bank Info',
      description: 'This is content my bank info.',
      image: '../../assets/images/tutor/bank-info.svg',
      url: ['/users/payout/account/create'],
      csssClasses: 'bg-color-green'
    },
    {
      title: 'My Earnings',
      description: 'This is content my earning.',
      image: '../../assets/images/tutor/my-earnings.svg',
      url: ['/users/payout/request'],
      csssClasses: 'bg-color-orange'
    },
    {
      title: 'My Transactions',
      description: 'This is content my transaction.',
      image: '../../assets/images/tutor/transactions.svg',
      url: ['/users/transaction/list'],
      csssClasses: 'bg-color-dark-green'
    },
    // {
    //  title: "My 1 on 1 Classes",
    //  description: "This is content my 1on1 class.",
    //  image: "../../assets/images/tutor/1-on-1-classes.svg",
    //  url: ['/users/schedule'],
    //  csssClasses: "bg-color-blue"
    //},
    // {
    //  title: "My Webinar Classes",
    //  description: "This is content my webinar class.",
    //  image: "../../assets/images/tutor/webinar-classes.svg",
    //  url: ['/users/topics'],
    //  csssClasses: "bg-color-dark-green"
    //},

    {
      title: 'My Messages',
      description: 'This is content my message.',
      image: '../../assets/images/tutor/my-messages.svg',
      url: ['/users/messages/conversations'],
      csssClasses: 'bg-color-blue'
    },
  
    {
      title: 'Courses Manager',
      description: 'Mange your courses here.',
      image: '../../assets/images/tutor/refund-request.svg',
      url: ['/users/courses'],
      csssClasses: 'bg-color-red'
    },
    {
      title: 'Course Transactions',
      description: 'Mange your courses transactions here.',
      image: '../../assets/images/tutor/refund-request.svg',
      url: ['/users/course-transaction'],
      csssClasses: 'bg-color-red'
    },
    {
      title: 'Refund Request',
      description: 'This is content refund request.',
      image: '../../assets/images/tutor/refund-request.svg',
      url: ['/users/refund/request'],
      csssClasses: 'bg-color-red'
    }
  ];

  tutorCardsSplited = _.chunk(this.tutorCards, 3);

  constructor(
    private router: Router,
    private toasty: ToastrService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private seoService: SeoService
  ) {
    seoService.update('Dashboard');
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
      const navigation = this.router.getCurrentNavigation();
      if (navigation && navigation.extras && navigation.extras.state && navigation.extras.state.current) {
        this.type = navigation.extras.state.current.type;
      }
    });
  }

  ngOnInit() {
    if (!this.type) {
      this.authService.getCurrentUser().then(resp => {
        this.type = resp.type;
      });
    }
  }
}
