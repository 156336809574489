import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';

declare var localStorage:any;
@Injectable({
  providedIn: 'root'
})
export class SystemService {
  public appConfig: any = null;

  private _getConfig: any;
  private languageChanged = new Subject<any>();
  //I Use This To Update Site Affter Change Language From Header In Every Time
  languageChangedFromHeader$ = new Subject<any>();

  public languageChanged$ = this.languageChanged.asObservable();

  owlOptions: OwlOptions = {
    autoplay: true,
    margin: 10,
    loop: false,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,

    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 2
      },
      768: {
        items: 2
      },
      // 992: { items: 4 },
      // 992: { items: 3 },
      1024: { items: 2 },
      1100: { items: 3 },
      1280: { items: 4 },
      // 1300: { items: 4 },
      1920: { items: 5 },
    },

    nav: true
  };
  constructor(private restangular: Restangular) {
    this.languageChanged.subscribe(lang => {
      this.owlOptions.rtl = this.checkRTL();
      this.owlOptions.navText = this.owlOptions.rtl ?
        ['<i class="fas fa-arrow-right"></i>', '<i class="fas fa-arrow-left"></i>'] :
        ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'];
    })
  }

  /**
   * If Current Direction Is RTL
   * @returns
   */
  isRTL(language: string): boolean {
    if (language) return language == 'ar';
    else return window['localStorage']?.getItem('rtl') == 'yes';
  }
  configs(): Promise<any> {
    if (this.appConfig) {
      if (window['localStorage']?.getItem('userLang')) {
        this.appConfig = Object.assign(this.appConfig, { userLang: window['localStorage']?.getItem('userLang') });
      }
      return Promise.resolve(this.appConfig);
    }

    if (this._getConfig && typeof this._getConfig.then === 'function') {
      return this._getConfig;
    }

    this._getConfig = this.restangular
      .one('system/configs/public')
      .get()
      .toPromise()
      .then(resp => {
        this.appConfig = resp.data;
        // load user lang here
        const userLang = window['localStorage']?.getItem('userLang') || resp.data.i18n.defaultLanguage || 'ar';
        this.appConfig.userLang = userLang;
        // this.languageChanged.next(window['localStorage']?.getItem('userLang'));
        return this.appConfig;
      });
    return this._getConfig;
  }

  setUserLang(lang: string) {
    window['localStorage']?.setItem('userLang', lang || 'ar');
    if (lang === 'ar') {
      // (document.getElementById('dynamiCss') as HTMLLinkElement).href = 'assets/rtl.css';
      window['localStorage']?.setItem('rtl', 'yes');
    } else {
      // (document.getElementById('dynamiCss') as HTMLLinkElement).href = 'assets/ltr.css';
      window['localStorage']?.setItem('rtl', 'no');
    }
    this.languageChanged.next(lang);
  }

  checkRTL() {
    return window['localStorage']?.getItem('rtl') === 'yes';
  }
}
