import { Injectable } from '@angular/core';
import { RestangularModule, Restangular } from 'ngx-restangular';


@Injectable()
export class AppointmentService {
  constructor(private restangular: Restangular) { }

  search(params: any): Promise<any> {
    return this.restangular.one('appointments').get(params).toPromise();
  }

  findOne(id): Promise<any> {
    return this.restangular.one('appointments', id).get().toPromise();
  }
}
