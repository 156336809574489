import { Injectable } from '@angular/core';
import { RestangularModule, Restangular } from 'ngx-restangular';

@Injectable()
export class TopicService {
  public topics: any = null;
  private _getTopics: any;
  constructor(private restangular: Restangular) {}

  getTopics(params: any): Promise<any> {
    if (this.topics) {
      return Promise.resolve(this.topics);
    }

    if (this._getTopics && typeof this._getTopics.then === 'function') {
      return this._getTopics;
    }

    this._getTopics = this.restangular
      .one('topics')
      .get(params)
      .toPromise()
      .then(resp => {
        this.topics = resp;
        return this.topics;
      });
    return this._getTopics;
  }

  create(params: any): Promise<any> {
    return this.restangular.all('topics').post(params).toPromise();
  }

  search(params: any): Promise<any> {
    return this.restangular.one('topics').get(params).toPromise();
  }

  homePageList(tutorId:string=null,featured:boolean=null,take=null): Promise<any> {
    
    return this.restangular.one('topics/homePageList').get({tutorId,featured,take}).toPromise();
  }
  
  findOne(id): Promise<any> {
    return this.restangular.one('topics', id).get().toPromise();
  }

  update(id, data): Promise<any> {
    return this.restangular.one('topics', id).customPUT(data).toPromise();
  }

  delete(id): Promise<any> {
    return this.restangular.one('topics', id).customDELETE().toPromise();
  }

  checkUsedCoupon(params: any): Promise<any> {
    return this.restangular.all('coupons/check-used-coupon').post(params).toPromise();
  }

  findSingleCoupon(id, params): Promise<any> {
    return this.restangular.one('coupons', id).get(params).toPromise();
  }
}
