import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
@Injectable({providedIn:"root"})
export class PlatformService {

    constructor(@Inject(PLATFORM_ID) private platFormId) {

    }


    /**
     * If Current Platform Is Brwoser
     */
    isBrowser() {
        
        return isPlatformBrowser(this.platFormId)
    }

}//End Class