import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { RestangularModule } from 'ngx-restangular';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { DashboardLayoutComponent } from './layouts/dashboard/dashboard.component';
import 'rxjs/add/operator/do';

import { AuthService, CategoryService } from './shared/services';
import { AuthGuard } from './shared/guard/auth.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { ConfigResolver, LanguageResolver, CategoryResolver } from './shared/resolver';
import { UtilsModule } from './utils/utils.module';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { OurMissingTranslationHandler } from './shared/services/our.missing.translation.handler.service';
import { TutorAuthGuard } from './shared/guard/tutor.auth.guard';
import { environment } from '../environments/environment';
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image'; // <-- import it

declare var localStorage:any;
 
const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
const defaultTimeout = 10000;







// Function for setting the default restangular configuration
export function RestangularConfigFactory(RestangularProvider) {

  // TODO - change default config
RestangularProvider.setBaseUrl(environment.apiBaseUrl);
  RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {

    // Auto add token to header
    headers.Authorization = 'Bearer ' +window['localStorage']?.getItem('accessToken');
    headers.TimeZone=Intl.DateTimeFormat().resolvedOptions().timeZone;
    return {
      headers: headers
    };
  });

  RestangularProvider.addErrorInterceptor((response, subject, responseHandler) => {
    // force logout and relogin
    if (response.status === 401) {
      window['localStorage']?.removeItem('accessToken');
      window['localStorage']?.removeItem('isLoggedin');
      // window.location.href = '/auth/login';

      return false; // error handled
    }

    return true; // error not handled
  });
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.apiBaseUrl}/i18n/`, '.json');
  // return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    BlankComponent,
    FullComponent,
    DashboardLayoutComponent,
    ScrollToTopComponent
    // SlickCarouselComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    // SlickCarouselModule,
    NgbModule,
    RouterModule.forRoot(Approutes, { useHash: false, relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled'  }),
    // Importing RestangularModule and making default configs for restanglar
    RestangularModule.forRoot(RestangularConfigFactory),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: OurMissingTranslationHandler
      }
    }),
    UtilsModule,
    // FontAwesomeModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy // HashLocationStrategy
    },
    AuthService,
    AuthGuard,
    TutorAuthGuard,
    ConfigResolver,
    LanguageResolver,
    CategoryResolver,
    CategoryService,
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    { provide: DEFAULT_TIMEOUT, useValue: defaultTimeout }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
