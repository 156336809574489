import { Subject } from 'rxjs';
import { Host, Inject, Injectable } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';
import { TrnaslateService } from '../../utils/services/trnaslatee.service';
import { ISEO } from '../../utils/interface';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private seoChanged = new Subject<any>();
  public seoChanged$ = this.seoChanged.asObservable();



  get html(): HTMLElement {
    return this.document.getElementsByTagName('html')[0];
  }

  get doc(): Document {
    return this.document;
  }


  constructor(private meta: Meta, private title: Title, private ts: TrnaslateService, @Inject(DOCUMENT) private document) { }


  /**
   * Update Static Seo Like ('Home Page','PostsPage','VideosPage',....)
   * @param seo 
   */
  updateSeo(seo: ISEO): void {
    
    this.title.setTitle(this.ts.getValue(seo.title));
    if(seo.description)
    this.updateValue("description", this.ts.getValue(seo.description));
    if(seo.keywords)
    this.updateValue("keywords", this.ts.getValue(seo.keywords));
    // this.update("image", seo.picturePath);
if(seo.title)
    this.updateProp("og:title", this.ts.getValue(seo.title));
    if(seo.description)
    this.updateProp("og:description", this.ts.getValue(seo.description));
    if(seo.keywords)
    this.updateProp("og:keywords", this.ts.getValue(seo.keywords));
    // this.updateProp("og:image", seo.picturePath);
    this.meta.removeTag("property='og:image:width'");
    this.meta.removeTag("property='og:image:height'");
  }

  private updateValue(name: string, content: string) {
    this.meta.updateTag({ name, content });
  }

  private updateProp(name: string, content: string) {
    this.meta.updateTag({ property: name, content });
  }

  update(title: string, meta?: any) {
    this.seoChanged.next({
      title,
      meta
    });
  }



}
