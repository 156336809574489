import { PostService } from './../../../shared/services/posts.service';
import { SystemService } from './../../../shared/services/system.service';
import { AuthService } from './../../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.html'
})
export class FooterComponent implements OnInit {
  public appConfig: any;
  public subjects: any = [];
  public posts: any = [];
  public config: any = {};
  public textButton = 'Register';
  public isLoggedin: boolean = false;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastService: ToastrService,
    private systemService: SystemService,
    private postService: PostService
  ) {
    this.systemService.languageChanged$.subscribe(lang => {
      this.postService.search({ take: 100, language: lang }).then(resp => {
        this.posts = resp.data.items.filter((item) => {
          return item.alias != 'faqs-ar' && item.alias != 'faqs-en' && item.alias != 'about_ar' && item.alias != 'about-en';
        });
        // console.log(this.posts);
        
      });
    });

    this.appConfig = window['appConfig'];
    const subjects = this.route.snapshot.data['subjects'];
    this.subjects = subjects;
    // const postsData = this.route.snapshot.data['posts']

    // this.posts = postsData;    
    // console.log(this.posts);

    this.config = this.route.snapshot.data['appConfig'];
  }

  ngOnInit() {
    this.isLoggedin = this.authService.isLoggedin();
  }

  register() {
    if (!this.isLoggedin) {
      this.router.navigate(['/auth/sign-up']);
    } else {
      if (window.confirm('Do you want to log out?')) {
        this.authService.removeToken();
        this.router.navigate(['/auth/sign-up']);
      }
    }
  }
}
