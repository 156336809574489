import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { MediaModule } from '../media/media.module';
import { DefaultImagePipe, EllipsisPipe } from './pipes';
import { AuthService, SubjectService, PostService, CouponService, FavoriteService } from '../shared/services';
import { NewsletterService } from './services/newsletter.service';
import { SubjectsResolver, PostsResolver } from '../shared/resolver';
import {
  UserLeftMenuComponent,
  SidebarComponent,
  FooterComponent,
  HeaderComponent,
  BreadcrumbComponent,
  SearchBarComponent,
  DateRangeComponent,
  AppointmentStatusComponent,
  StatusComponent,
  TimezoneComponent,
  ButtonSignupComponent,
  ModalSignupComponent,
  SortComponent,
  NewsleterComponent,
  TableComponent,
  ApplyCouponComponent,
  LectureItemComponent,
  TextEllipsisComponent,
  SendMessageButtonComponent,
  MessageMessageModalComponent
} from './components';
import { CardTextComponent } from './components/card-text.component';
// import { MessageModule } from '../message/message.module';
import { ConversationService } from '../message/services/conversation.service';
import { MessageService } from '../message/services/message.service';
import { UserService } from '../user/services/user.service';
import { OurMissingTranslationHandler } from '../shared/services/our.missing.translation.handler.service';
import { CourseModule } from '../course/course.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DorosnaVideoPlayerComponent } from './components/dorosna-video-player/dorosna-video-player.component';
import { DorosnaAudioPlayerComponent } from './components/dorosna-audio-player/dorosna-audio-player.component';
import { CouponModelComponent } from './components/coupon/coupon-model/coupon-model.component';
import { CouponListComponent } from '../user/component';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { StarRatingComponent } from '../reviews/components';
import { CardCourseComponent } from '../course/card-course/card-course.component';
import { CardTopicComponent } from '../topic/card-topic/card-topic.component';
import { AddToCartIconComponent } from './components/add-to-cart-icon/add-to-cart-icon.component';
@NgModule({
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: OurMissingTranslationHandler
      }
    }),
    NgSelectModule,
    MediaModule,
    CarouselModule,
    TooltipModule.forRoot(),
    LazyLoadImageModule


    // MessageModule
  ],
  declarations: [
    BreadcrumbComponent,
    SidebarComponent,
    FooterComponent,
    HeaderComponent,
    UserLeftMenuComponent,
    NewsleterComponent,
    DefaultImagePipe,
    SearchBarComponent,
    DateRangeComponent,
    ButtonSignupComponent,
    ModalSignupComponent,
    AppointmentStatusComponent,
    StatusComponent,
    EllipsisPipe,
    SortComponent,
    TimezoneComponent,
    TableComponent,
    ApplyCouponComponent,
    LectureItemComponent,
    CardTextComponent,
    TextEllipsisComponent,
    SendMessageButtonComponent,
    MessageMessageModalComponent,
    DorosnaVideoPlayerComponent,
    DorosnaAudioPlayerComponent,
    CouponModelComponent,
    CouponListComponent,
    StarRatingComponent,
    CardCourseComponent,
    CardTopicComponent,
    AddToCartIconComponent


  ],
  exports: [
    BreadcrumbComponent,
    SidebarComponent,
    FooterComponent,
    HeaderComponent,
    UserLeftMenuComponent,
    NewsleterComponent,
    DefaultImagePipe,
    TranslateModule,
    SearchBarComponent,
    DateRangeComponent,
    ButtonSignupComponent,
    ModalSignupComponent,
    AppointmentStatusComponent,
    StatusComponent,
    EllipsisPipe,
    SortComponent,
    TimezoneComponent,
    TableComponent,
    ApplyCouponComponent,
    LectureItemComponent,
    CardTextComponent,
    TextEllipsisComponent,
    SendMessageButtonComponent,
    MessageMessageModalComponent,
    TooltipModule,
    DorosnaVideoPlayerComponent,
    DorosnaAudioPlayerComponent,
    CouponModelComponent,
    CouponListComponent,
    LazyLoadImageModule,
    StarRatingComponent,
    CardCourseComponent,
    CardTopicComponent,
    AddToCartIconComponent




  ],
  providers: [
    AuthService,
    SubjectService,
    PostService,
    NewsletterService,
    SubjectsResolver,
    PostsResolver,
    CouponService,
    MessageService,
    ConversationService,
    UserService,
    PostService,
    FavoriteService,
  ],
  entryComponents: [ModalSignupComponent, MessageMessageModalComponent]
})
export class UtilsModule {}
