import { SeoService } from './../../../../shared/services/seo.service';
import { Component, OnInit } from '@angular/core';
import { TopicService, AuthService, SystemService } from '../../../../shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { ITopic } from '../../../../topic/interface';
import { IUser } from '../../../interface';
import { Subscription } from 'rxjs';
import { TutorService } from '../../../../tutor/services/tutor.service';
import { TrnaslateService } from '../../../../utils/services/trnaslatee.service';
import { OurToastrService } from '../../../../shared/services/our.toastr.service';
import { PlatformService } from '../../../../utils/platform.service';
declare var $: any;

@Component({
  selector: 'topic-listing',
  templateUrl: './list.html',
  styleUrls: ['./list.component.css']
})
export class TopicListingComponent implements OnInit {
  public total: number = 0;
  public items: ITopic[];
  public currentPage: number = 1;
  public pageSize: number = 10;
  public searchFields: any = {
    gradeId: '',
    subjectId: ''
  };
  public sortOption = {
    sortBy: 'createdAt',
    sortType: 'desc'
  };
  public currentUser: IUser;
  public fromItem: number = 0;
  public toItem: number = 0;
  public timeout: any;
  public config: any;
  public rtl: boolean = false;
  public langChange: Subscription;
  grades = [];
  subjects = [];
  constructor(
    private router: Router,
    private topicService: TopicService,
    private toasty: OurToastrService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private seoService: SeoService,
    private tutorService: TutorService,
    private systemService: SystemService,
    private trnaslateService: TrnaslateService,
    private pt: PlatformService
  ) {
    seoService.update('My Webinars');
    this.config = this.route.snapshot.data['appConfig'];
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.rtl = this.systemService.checkRTL();
    });
  }

  ngOnInit() {
    this.auth.getCurrentUser().then(resp => {
      this.currentUser = resp;
      if (this.currentUser._id) {
        this.getTutorGradesAndSubjects();
        this.query();
      }
    });
    this.rtl = this.systemService.checkRTL();
  }

  getTutorGradesAndSubjects() {
    this.tutorService
      .getTutorGradesAndSubjects(this.currentUser?._id)
      .then(resp => {
        this.grades = resp.data.grades;
        this.subjects = resp.data.subjects;
        this.trnaslateService.appendLableOnItems(this, 'subjects');
        this.trnaslateService.appendLableOnItems(this, 'grades');
      })
      .catch(() => {
        console.log('Something went wrong, please try again!');
      });
  }

  query() {
    let params = Object.assign(
      {
        page: this.currentPage,
        take: this.pageSize,
        sort: `${this.sortOption.sortBy}`,
        sortType: `${this.sortOption.sortType}`,
        tutorId: this.currentUser._id
      },
      this.searchFields
    );
    this.topicService
      .search(params)
      .then(resp => {
        this.total = resp.data.count;
        this.items = resp.data.items;
        if (this.currentPage === 1) {
          this.fromItem = this.currentPage;
          this.toItem = this.items.length;
        } else if (this.currentPage > 1) {
          this.fromItem =
            this.currentPage * this.pageSize > this.total
              ? (this.currentPage - 1) * this.pageSize
              : this.currentPage * this.pageSize;
          this.toItem = this.fromItem + this.items.length;
        }

        if (!this.items.length)
        this.fromItem=0;
      })
      .catch(() => this.toasty.error('Something went wrong, please try again!'));
  }

  doSearch(evt) {
    const searchText = evt.target.value; // this is the search text
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(() => {
      this.searchFields.name = searchText;
      this.query();
    }, 400);
  }

  showChange(evt) {
    this.pageSize = evt.target.value;
    this.query();
  }

  sortBy(field: string, type: string) {
    this.sortOption.sortBy = field;
    this.sortOption.sortType = type;
    this.query();
  }

  onSort(evt) {
    this.sortOption = evt;
    this.query();
  }

  remove(item: any, index: number) {
    if (window.confirm(this.trnaslateService.getValue('Are you sure want to delete this topic?'))) {
      this.topicService
        .delete(item._id)
        .then(() => {
          this.toasty.success('Item has been deleted!');
          this.items.splice(index, 1);
        })
        .catch(e => this.toasty.error(e.data.data.message));
    }
  }
  pageChange() {
            if(this.pt.isBrowser())
 $('html, body').animate({ scrollTop: 0 });
    this.query();
  }
}
