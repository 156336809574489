import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { TopicService } from '../services';
import { StarterService } from '../services/starter.service';

@Injectable()
export class StarterResolver implements Resolve<Observable<any>> {
  constructor(private service: StarterService) {}

  resolve(): any {
    return this.service
      .getItems()
      .then(resp => resp.data);
  }
}
