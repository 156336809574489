
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { event } from "jquery";


@Component({
  selector: "dorosna-audio-player",
  templateUrl: "./dorosna-audio-player.html",
})
export class DorosnaAudioPlayerComponent implements OnInit {

  @Input('src') src;


  constructor() { }
  ngOnInit(): void {



  }


  /** On Right Click */
  onRightClick(ev) {
    ev.preventDefault();
  }

}//End Class
