import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../services';

@Injectable()
export class TutorAuthGuard implements CanActivate {

  constructor(private router: Router, private Auth: AuthService) { }

  canActivate() {
         const canActive = this.Auth.getCurrentUserType()=="tutor";
        if (!canActive) {
          this.router.navigate(['/']);
          return false;
        }
        return true;
  }
}
