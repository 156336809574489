export const environment = {
  production: true,
  version: '0.0.1',
  build: 3,
  showBuild: false,
  url: 'https://dorosona.com',
  apiBaseUrl: 'https://api.dorosona.com/v1',

  stripeKey: 'pk_test_GAOwgdMyHnXkj9v9HcTCyu7E00kA8x0Oh1',
  socketUrl: 'https://api.dorosona.com',
  maximumFileSizeVideo: 500,
  maximumFileSizeImage: 5,
  maximumFileSizeAudio: 10,
  maximumFileSizePDF: 10,
  apiUrl: 'https://api.dorosona.com/'
};
