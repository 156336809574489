import { CalendarService } from './../../../../calendar/services/calendar.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService, CategoryService, CourseService, SystemService } from '../../../../shared/services';
import { ICourse } from '../../../../course/interface';
import { ICategory } from '../../../../learn-with-us/interface';
import { pick } from 'lodash';
import { Subscription } from 'rxjs';
import { TutorService } from '../../../../tutor/services/tutor.service';
import { TrnaslateService } from '../../../../utils/services/trnaslatee.service';
import { OurToastrService } from '../../../../shared/services/our.toastr.service';
import { environment } from '../../../../../environments/environment';
import { Sweetalert2Service } from '../../../../utils/services/sweetalert2.service';
import { PlatformService } from '../../../../utils/platform.service';
declare var $: any;
@Component({
  selector: 'app-course-create',
  templateUrl: '../form.html',
  styleUrls: [
    '../../../../../assets/quill/quill.core.css',
    '../../../../../assets/quill/quill.bubble.css',
    '../../../../../assets/quill/quill.snow.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class CourseCreateComponent implements OnInit {
  public maxFileSize: any;
  public tab: number = 1;
  public course: ICourse = {
    name: '',
    price: 0,
    categoryIds: [],
    description: '',
    mainImageId: '',
    introductionVideoId: '',
    alias: '',
    isFree: false,
    gradeId: '',
    subjectId: ''
  };
  descriptionError = false;
  public loading: boolean = false;
  public isFree: boolean = false;
  public categories: ICategory[] = [];
  public isSubmitted: Boolean = false;
  public mainImageOptions: any;
  public videoOptions: any;
  public mainImageUrl: String = '';
  public videoUrl: String = '';
  public imageSelected: any[] = [];
  public videoSelected: any[] = [];
  public uploadingVideo: boolean = false;
  public uploadingImage: boolean = false;
  grades = [];
  subjects = [];

  public quillConfig = {
    magicUrl: true,
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block', 'link'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ font: [] }],
        [{ align: [] }],

        ['clean']
        // ['image']
      ]
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            return true;
          }
        },

      }
    }
  };
  public rtl: boolean = false;
  public langChange: Subscription;
  maximumFileSizeImage: number;
  maximumFileSizeVideo: number;
  constructor(
    private tutorService: TutorService,
    private courseService: CourseService,
    private toasty: OurToastrService,
    private categoryService: CategoryService,
    private trnaslateService: TrnaslateService,
    private auth: AuthService,
    private systemService: SystemService,
    private sweetalert: Sweetalert2Service,
    private router: Router,
    private authService: AuthService,
    private pt: PlatformService

  ) {
    this.maximumFileSizeImage = window['appConfig']?.maximumFileSizeImage;
    this.maximumFileSizeVideo = window['appConfig']?.maximumFileSizeVideo;
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.rtl = this.systemService.checkRTL();
    });
  }

  ngOnInit() {
    this.getTutorGradesAndSubjects();

    this.rtl = this.systemService.checkRTL();
    this.mainImageOptions = {
      url: environment.apiBaseUrl + '/media/photos',
      fileFieldName: 'file',
      autoUpload: true,
      maxFileSize: environment.maximumFileSizeImage,
      onCompleteItem: resp => {
        this.course.mainImageId = resp.data._id;
        this.mainImageUrl = resp.data.thumbUrl;
        this.uploadingImage = false;
      },
      onFileSelect: resp => (this.imageSelected = resp),
      accept: 'image/*',
      id: 'image-upload',
      onUploading: resp => (this.uploadingImage = true)
    };

    this.videoOptions = {
      url: environment.apiBaseUrl + '/media/videos',
      fileFieldName: 'file',
      autoUpload: true,
      maxFileSize: environment.maximumFileSizeVideo,
      onCompleteItem: resp => {
        this.course.introductionVideoId = resp.data._id;
        this.videoUrl = resp.data.fileUrl;
        this.uploadingVideo = false;
      },
      onFileSelect: resp => (this.videoSelected = resp),
      id: 'file-upload',
      accept: 'video/*',
      onUploading: resp => (this.uploadingVideo = true)
    };


  }

  getTutorGradesAndSubjects() {
    this.tutorService.getTutorGradesAndSubjects()
      .then(resp => {

        //Check If Current Tutor Not Have Subjects
        if (resp.data.subjects.length == 0)
          this.sweetalert.confirmWarning("Tutor no subjects alert", "Ok, go to profile", "No, back to dashboard")
            .then(action => {
              if (action.isConfirmed)
                return this.router.navigate(["/users", "profile"]);
              else
                return this.router.navigate(["/users", "dashboard"]);
            });

        this.grades = resp.data.grades;
        this.subjects = resp.data.subjects;
        this.trnaslateService.appendLableOnItems(this, 'subjects');
        this.trnaslateService.appendLableOnItems(this, 'grades');
      })
      .catch(() => {
        this.loading = false;
        console.log('Something went wrong, please try again!');
      });
  }




  submit(frm: any) {
    this.isSubmitted = true;


    if (!frm.valid || (this.course.price <= 0 && !this.course.isFree) || this.descriptionError) {
      return this.toasty.error('Invalid form, please try again.');
    }


    if (this.course.description) {
      this.course.description = this.course.description?.replace(
        '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
        ''
      );
    }

    if (this.course.isFree === true) this.course.price = 0;

    if (!this.course.mainImageId) this.toasty.error('Please upload main image for course!');
    if (!this.course.introductionVideoId) this.toasty.error('Please upload introduction video for course!');

    if (!this.course._id && this.course.mainImageId && this.course.introductionVideoId) {
      this.courseService.create(this.course).then(
        resp => {
          this.toasty.success('Course has been created');
          this.course = resp.data;
          this.tab = 2;
          if (this.pt.isBrowser())
            $('html, body').animate({ scrollTop: 100 });
          //this.router.navigate(['/users/courses/' + resp.data._id]);
        },
        err => this.toasty.error(err.data.message || 'Something went wrong!')
      );
    } else if (this.course._id) {
      if (this.course.isFree === true) this.course.price = 0;
      this.courseService
        .update(
          this.course._id,
          pick(this.course, [
            'name',
            'price',
            'description',
            'alias',
            'gradeId',
            'subjectId',
            'introductionVideoId',
            'mainImageId',
            'isFree'
          ])
        )
        .then(resp => {
          this.toasty.success('Updated successsfully!');
          this.tab = 2;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.toasty.error((err.data.data && err.data.data.message) || err.data.message || err.data.email);
        });
    }
  }

  onTabSelect(tab: number) {
    this.tab = tab;
    if (this.pt.isBrowser())
      $('html, body').animate({ scrollTop: 100 });
  }
}
