import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../../shared/services';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { TrnaslateService } from '../../../utils/services/trnaslatee.service';
import { Upload } from 'tus-js-client';

@Component({
  selector: 'app-tus-upload',
  templateUrl: './upload.html'
})
export class TusUploadComponent implements OnInit {
  /**
   * option format
   * {
   *  customFields: { key: value } // additional field will be added to the form
   *  query: { key: value } // custom query string
   * }
   */
  @Input() options: any;
  public multiple: Boolean = false;
  public uploadOnSelect: Boolean = false;
  public autoUpload: Boolean = false;
  public progress: any = null;
  private uploadedItems: any = [];
  public queue: any = [];
  public upload: any;
  public uploading: Boolean = false;

  constructor(private authService: AuthService, private toasty: ToastrService,
    public translate: TrnaslateService, private readonly changeDetectionRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.multiple = this.options && this.options.multiple;
    this.uploadOnSelect = this.options && this.options.uploadOnSelect;
    this.autoUpload = this.options && this.options.autoUpload;
    if (!this.options) {
      this.options = {};
    }
  }

  fileSelect(event) {
    const _this = this;
    _this.queue = [];
    _this.progress = 0;
    _.forEach(event.target.files, function (file) {
      if (_this.options.accept) {
        const accept = _this.accept(file.type, _this.options.accept);
        if (!accept) {
          return _this.toasty.error(_this.translate.getValue('Invalid file type'));
        }
        _this.queue.push(file);
      }
    });
    if (this.options.onFileSelect) {
      this.options.onFileSelect(this.queue);
    }

    if (this.options.onCulclateVideoDuration) {
      this.culclateDuration("video",this.queue[0],(duration)=>{
        this.options.onCulclateVideoDuration(duration);
      })
    }

    if (this.options.onCulclateAudioDuration) {
      this.culclateDuration("audio",this.queue[0],(duration)=>{
        this.options.onCulclateAudioDuration(duration);
      })
    }


    if (this.options.uploadOnSelect || this.options.autoUpload) {
      this.uploadAll();
    }
  }

  accept(fileType: string, accept: any) {
    const typeRegex = new RegExp(accept?.replace(/\*/g, '.*')?.replace(/\,/g, '|'));
    return typeRegex.test(fileType);
  }

  uploadFile(file) {
    const upload = new Upload(file, {
      endpoint: this.options.url,
      headers: {
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      },
      retryDelays: [0, 3000, 6000, 12000, 24000],
      chunkSize: 5 * 1024 * 1024,
      metadata: {
        filename: file.name,
        filetype: file.type,
        contentType: file.type
      },
      onError: async (error) => {
        return this.toasty.error('Upload error');
      },
      onProgress: (bytesSent, bytesTotal) => {
        this.progress = Math.floor(bytesSent / bytesTotal * 100);
        this.changeDetectionRef.detectChanges();
      },
      onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
        this.progress = Math.floor(bytesAccepted / bytesTotal * 100);
        this.changeDetectionRef.detectChanges();
      },
      onSuccess: async () => {
        this.uploadedItems.push({
          id: upload.url.substring(upload.url.lastIndexOf('/') + 1),
          // @ts-ignore
          name: upload.file.name,
          url: upload.url,
        });

        if (this.options.onCompleteItem) {
          this.options.onCompleteItem(this.options.multiple ? this.uploadedItems : this.uploadedItems[0]);
        }

        this.progress = null;
        this.queue = [];

      },

    });
    this.upload = upload;
    this.startOrResumeUpload(upload);
    // upload.start();
  }

  uploadAll() {
    const _this = this;
    this.progress = null;
    if (!this.queue.length) {
      return alert('Please select file');
    }


    if (this.options.onUploading) {
      this.options.onUploading(true);
    }

    _.forEach(this.queue, function (file) {
      _this.uploadFile(file);
    });

    if (this.options.onFinish) {
      this.options.onFinish(this.options.multiple ? this.uploadedItems : this.uploadedItems[0]);
    }

  }

  async startOrResumeUpload(upload, force = false) {
    this.uploading = true;
    const _this = this;
    upload.findPreviousUploads().then(function (previousUploads) {
      const sortedPreviousUploads = _.sortBy(previousUploads, function (o) {
        return new Date(o.creationTime);
      });

      const chosenUpload = _this.askToResumeUpload(sortedPreviousUploads, force);

      if (chosenUpload) {
        upload.resumeFromPreviousUpload(chosenUpload);
      }


      upload.start();
      _this.uploading = true;
    });
  }

  pause() {
    this.upload.abort();
    this.uploading = false;
  }

  resume() {
    this.startOrResumeUpload(this.upload, true);
  }

  askToResumeUpload(previousUploads, force = false) {
    if (previousUploads.length === 0) { return null; }

    if (force) {
      return previousUploads[previousUploads.length - 1];
    }

    let text = 'لقد قمت ببدأ رقع هذا الملف مسبقا. هل تريد استكمال الرفع؟\n';
    text += 'You already started uploading this file time. Do you want to resume this upload?';

    const answer = confirm(text);

    if (answer === true) {
      return previousUploads[previousUploads.length - 1];
    }
  }


  culclateDuration(itemType,item,callBack){
    let element = document.createElement(itemType)
    element.src = URL.createObjectURL(item);
    element.preload = 'metadata';
    element.onloadedmetadata = () => {
      window.URL.revokeObjectURL(element.src);
      var duration = element.duration.toFixed(2)
      console.log("duration",duration)
      callBack(duration);
    }
  }



}
