import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubjectService, SystemService } from '../../../shared/services';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { TrnaslateService } from '../../services/trnaslatee.service';
import { StarterService } from '../../../shared/services/starter.service';
import { PlatformService } from '../../platform.service';
import * as _ from 'lodash';
@Component({
  selector: 'search-bar',
  styleUrls: ['./search-bar.component.css'],
  templateUrl: './search-bar.html'
})
export class SearchBarComponent implements OnInit {
  @Input() sliders: any[];

  public subjectId: any;
  public gradeId: any;


  subjects: any = [];
  public infoMessage: string;
  pureTerm: string;
  infoMessageIsError: boolean;

  constructor(private systemService: SystemService, private router: Router, private trnalsteService: TrnaslateService,

    private subjectService: SubjectService, private pt: PlatformService) {

    this.systemService.languageChanged$.subscribe(language => {
      setTimeout(() => {
        this.updateLabels();

      }, 1000);
    });

  }



  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => {
        this.infoMessage = "";
        this.infoMessageIsError = false;

        this.pureTerm = term?.replace(/\!|\@|\#|\$|%|\^|&|\*|\(|\)/gi, '');
        if (this.pureTerm === '') {
          if (term) {
            this.infoMessage = "Enter Valid Value";
            this.infoMessageIsError = true;
          }
          return [];
        }
        else {
          let result = this.subjects?.filter(v => v.label.toLowerCase().indexOf(this.pureTerm.toLowerCase()) > -1)
          if (!result?.length)
            this.infoMessage = "No results found";
          else
            this.infoMessage = "";
          return result;
        }
      }
      )
    );
  formatter = (x: any) => x.label;

  ngOnInit() {

    if (this.pt.isBrowser()) {
      this.fillSubjects();
    }
  }

  submit() {
    if (this.infoMessageIsError)
      return
    // if (!this.subjectId) {
    //   this.router.navigate(['/tutors']);
    // }
    this.router.navigate(['/tutors'], { queryParams: { subjectId: this.subjectId, grade: this.gradeId } });
  }

  keyPress(event: any) {
    if (event.charCode === 13) {
      this.submit();
    }
  }

  onSelect(event: any) {

    this.subjectId = event.item && event.item.id ? event.item.id : '';
    this.gradeId = event.item && event.item.gradeId ? event.item.gradeId : '';
  }

  fillSubjects() {
    this.subjectService.getSubkectToSearch().then(res => {
      this.subjects = res.data
      this.updateLabels();
    })
  }
  updateLabels() {
    if (!this.subjects.length) return;
    this.subjects?.forEach(c => {
      c.label = this.trnalsteService.getValue(c.name)
    });
    this.subjects = _.orderBy(this.subjects,['label'])
  }
}
