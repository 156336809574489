import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { TopicService } from '../services';

@Injectable()
export class TopicResolver implements Resolve<Observable<any>> {
  constructor(private service: TopicService) {}

  resolve(): any {
    return this.service
      .search({ take: 6, sort: 'createdAt', sortType: 'asc', isOpen: true })
      .then(resp => resp.data.items);
  }
}
