import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Restangular } from 'ngx-restangular';
import { LanguageService } from '../../shared/services';
import { TrnaslateService } from '../../utils/services/trnaslatee.service';

@Injectable()
export class TutorService {

  constructor(private restangular: Restangular) { }

  search(params: any): Promise<any> {
    return this.restangular.one('tutors').get(params).toPromise();
  }

  getTutorGradesAndSubjects(id?: any): Promise<any> {
    return this.restangular.one('tutors/getTutorGradesAndSubjects').get(id ? { tutorId: id } : null).toPromise();
  }

 
  

  findOne(id): Promise<any> {
    return this.restangular.one('tutors', id).get().toPromise();
  }
  update(data): Promise<any> {
    return this.restangular.one('tutors').customPUT(data).toPromise();
  }

  updateDescription(data): Promise<any> {
    return this.restangular.one('tutors/description').customPUT(data).toPromise();
  }

  updateDefaultMeetingUrl(data): Promise<any> {
    return this.restangular.one('tutors/defaultMeetingUrl').customPUT(data).toPromise();
  }
  

  updateGradeAndSubjects(data): Promise<any> {
    return this.restangular.one('tutors/updateGradeAndSubjects').customPUT(data).toPromise();
  }

  createCertificate(data: any): Promise<any> {
    return this.restangular.all('certificates').customPOST(data).toPromise();
  }

  updateCertificate(id, data: any): Promise<any> {
    return this.restangular.one('certificates', id).customPUT(data).toPromise();
  }

  deleteCertificate(id): Promise<any> {
    return this.restangular.one('certificates', id).customDELETE().toPromise();
  }

  /**
   * Build Auto Description
   */
  buildAutoDescription(tutors: any[], ts: TrnaslateService, languageService: LanguageService) {

    if (!tutors || tutors.length == 0) return;

    tutors.forEach(tutor => {
      tutor.autoDescription = `${ts.getValue('Hello i\'m')} ${tutor.name} ${ts.getValue('and i can teach')}`;

      if (tutor.subjects?.length) {
        tutor.subjects?.forEach((sub, index) => {
          if (index > 0)
            tutor.autoDescription += ` ${ts.getValue('and')} `;

          tutor.autoDescription += ` ${ts.getValue(sub.name)} `;
        })
      }

      // if (tutor.gradeItems?.length) {
      //   tutor.autoDescription += ` ${ts.getValue((tutor.gradeItems.length > 1 ? 'for grades' : 'for grade'))} `;
      //   tutor.gradeItems.forEach((grade, index) => {
      //     if (index > 0)
      //       tutor.autoDescription += ` ${ts.getValue('and')} `;

      //     tutor.autoDescription += ` ${ts.getValue(grade.name)} `;
      //   })
      // }


      if (tutor.languages?.length) {
        tutor.autoDescription += ` ${ts.getValue(tutor.languages.length > 1 ? 'by languages' : 'by language')} `;
        tutor.languages?.forEach((lang, index) => {
          if (index > 0)
            tutor.autoDescription += ` ${ts.getValue('and')} `;

          tutor.autoDescription += ` ${ts.getValue(languageService.languages[lang])} `;
        })
      }
    });

  }
}
