import { Component, Input, OnInit } from '@angular/core';
import { AuthService, SeoService } from '../../../shared/services';
import { OurToastrService } from '../../../shared/services/our.toastr.service';
import { ShoppingCartService } from '../../../shopping-cart/service/shopping-cart.service';

@Component({
  selector: 'app-add-to-cart-icon',
  templateUrl: './add-to-cart-icon.html',
  styleUrls: []
})
export class AddToCartIconComponent implements OnInit {

  @Input('targetType') targetType: string;
  @Input('tutorId') tutorId: string;
  @Input('topicId') topicId: string;
  @Input('courseId') courseId: string;
  @Input('soloId') soloId: any;
  @Input('isBookSolo') isBookSolo: any;
  @Input('subjectId') subjectId: string;
  @Input('gradeId') gradeId: string;
  @Input('isFree') isFree: boolean;
  

  constructor(
    private cartServce: ShoppingCartService,
    private seoService: SeoService,
    private auth: AuthService,
    private toasty: OurToastrService


  ) {
  }
  ngOnInit() {
  }

  addToCart() {

    if (!this.auth.isLoggedin()) {
      return this.toasty.error('Please login to use cart!');
    }

    if (this.targetType == "solo" && (!this.subjectId || !this.gradeId)) {
      return this.toasty.error('Please choose subject');
    }

    this.cartServce.addItemToCart({
      targetType: this.targetType,
      tutorId: this.tutorId,
      courseId: this.courseId,
      topicId: this.topicId,
      subjectId: this.subjectId,
      slotId: this.soloId,
      isFree: this.isFree,
      
      gradeId: this.gradeId
    }).then(resp => {
      this.cartServce.cart.next(resp.data)
      this.toasty.success('Item added to cart successfully');
    }).catch(error => {
      this.toasty.error(error.data?.data?.message || error.data?.message || 'Something went wrong, please try again!');
    })
  }

}
