import { Component, OnInit } from '@angular/core';
import { AuthService, AppointmentService, SeoService, SystemService, SubjectService } from '../../../../shared/services';
import { IMylesson, IUser } from '../../../interface';
import { from, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { GradeService } from '../../../../tutor/services/grade.service';
import { TrnaslateService } from '../../../../utils/services/trnaslatee.service';
import { OurToastrService } from '../../../../shared/services/our.toastr.service';
import { PlatformService } from '../../../../utils/platform.service';
import { ITopic } from '../../../../topic/interface';
import { EnrollService } from '../../../../shared/services/enroll.service';
declare var $: any;
@Component({
  selector: 'app-list-lesson',
  styleUrls: ['./list.component.css'],
  templateUrl: './list.html'
})
export class ListLessonComponent implements OnInit {
  public currentUser: IUser;
  public currentPage: number = 1;
  public pageSize: number = 10;
  public total: number = 2;
  public searchFields: any = {
    topicId: '',
    subjectId: '',
    isTodayLesson: false,
    topicSubjectId: ''
  };
  public sortOption = {
    sortBy: 'startTime',
    sortType: 'desc'
  };
  public appointments: IMylesson[] = [];
  public count: number = 0;
  public config: any;
  public timeout: any;
  public rtl: boolean = false;
  public langChange: Subscription;
  topics: ITopic[] = [];
  subjects: any[] = [];
  topicSubjects = [];
  loading = {
    items: false,
    subjects: false,
  }
  constructor(
    private auth: AuthService,
    private appointmentService: AppointmentService,
    private enrollService: EnrollService,
    private route: ActivatedRoute,
    private toasty: OurToastrService,
    private seoService: SeoService,
    private systemService: SystemService,
    private pt: PlatformService

  ) {
    seoService.update('My Lessons');
    this.config = this.route.snapshot.data['appConfig'];
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.rtl = this.systemService.checkRTL();
    });
  }

  ngOnInit() {
    this.rtl = this.systemService.checkRTL();
    if (this.auth.isLoggedin()) {
      this.auth.getCurrentUser().then(resp => {
        this.currentUser = resp;
        this.query(true);
      });
    }
    this.getStudentEnrolledItems();
  }

  getStudentEnrolledItems() {
    this.enrollService.getStudentEnrolledItems({
      getSolos: true,
      getTopicSubjects: true
    })
      .then(res => {
        this.topicSubjects = res.data.topicSubjects;
        this.subjects = res.data.subjects;
      })
  }



  getTopics() {
      this.searchFields.enrollType = (this.searchFields.subjectId != '') ? 'solo' : '';
    if (this.searchFields.topicId != '') {
      this.searchFields.topicId = '';
      this.query(true);
    }

    if (this.searchFields.topicSubjectId == '') {
      this.topicSubjects = [];
      return;
    }

    this.enrollService.getStudentEnrolledItems({
      getTopics: true,
      subjectId: this.searchFields.topicSubjectId
    })
      .then(res => {
        this.topics = res.data.topics;
      })
  }

  query(isReset) {
    this.loading.items = true;
    let params = Object.assign(
      {
        page: this.currentPage,
        take: this.pageSize,
        sort: `${this.sortOption.sortBy}`,
        sortType: `${this.sortOption.sortType}`,
        userId: this.currentUser._id
      },
      this.searchFields
    );
    if (this.searchFields.isTodayLesson) {
      let dt = new Date();
      params['startTime_from'] = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()).toISOString();
      params['startTime_to'] = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + 1).toISOString();
    }
    this.appointments = [];
    if (isReset) {
      this.count = 0;
    }
    this.appointmentService
      .search(params)
      .then(resp => {
        this.count = resp.data.count;
        this.appointments = resp.data.items;
        this.total = resp.data.count;
        this.loading.items = false;
      })
      .catch(() => {
        this.loading.items = false;
        this.toasty.error('Something went wrong, please try again!')
      });
  }

  sortBy(field: string, type: string) {
    this.sortOption.sortBy = field;
    this.sortOption.sortType = type;
    this.query(true);
  }

  onSort(evt) {
    this.sortOption = evt;
    this.query(true);
  }

  pageChange() {
    if (this.pt.isBrowser())
      $('html, body').animate({ scrollTop: 0 });
    this.query(false);
  }

  doSearch(evt) {
    const searchText = evt.target.value; // this is the search text
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(() => {
      this.searchFields.description = searchText;
      this.query(true);
    }, 400);
  }


  topicFilter() {
    this.searchFields.enrollType = (this.searchFields.topicId != '') ? 'webinar' : '';
    this.searchFields.subjectId = '';
    this.searchFields.isTodayLesson = false;
    this.query(true);
  }
  soloFilter() {
    this.searchFields.enrollType = (this.searchFields.subjectId != '') ? 'solo' : '';
    this.searchFields.topicId = '';
    this.searchFields.topicSubjectId='';
    this.searchFields.isTodayLesson = false;
    this.query(true);
  }


  changeTodayLesson() {
    this.searchFields.isTodayLesson = !this.searchFields.isTodayLesson;
    this.searchFields.topicId = '';
    this.searchFields.topicSubjectId='';
    this.searchFields.subjectId = '';
    this.searchFields.enrollType = '';
    this.query(true)
  }
}
