import { Injectable } from '@angular/core';
import { RestangularModule, Restangular } from 'ngx-restangular';

@Injectable()
export class SliderService {
  public sliders: any = null;
  private _getSliders: any;
  constructor(private restangular: Restangular) {}

  getSliders(params: any): Promise<any> {
    if (this.sliders) {
      return Promise.resolve(this.sliders);
    }

    if (this._getSliders && typeof this._getSliders.then === 'function') {
      return this._getSliders;
    }

    this._getSliders = this.restangular
      .one('sliders')
      .get(params)
      .toPromise()
      .then(resp => {
        this.sliders = resp;
        return this.sliders;
      });
    return this._getSliders;
  }

  search(params: any): Promise<any> {
    return this.restangular.one('sliders').get(params).toPromise();
  }
}
