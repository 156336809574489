import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-sort',
  templateUrl: './sort.html'
})
export class SortComponent implements OnInit {
  @Input() sortOption: { [key: string]: string };
  @Input() sortBy: string;
  @Output() onSort = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  sort(field: string, type: string) {
    if(field==this.sortOption.sortBy)
    {
      this.sortOption.sortType =this.sortOption.sortType =='desc'?'asc':'desc';
    }else{
      this.sortOption.sortBy = field;
      this.sortOption.sortType = 'desc';
      
    }
      this.onSort.emit(this.sortOption);
  }
}
