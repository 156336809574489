import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TopicService, CategoryService, SystemService, AuthService } from '../../../../shared/services';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { randomHash } from '../../../../utils/utils';
import { CalendarService } from '../../../../calendar/services/calendar.service';
import { ITopic } from '../../../../topic/interface';
import { ICategory } from '../../../../learn-with-us/interface';
import { Subscription } from 'rxjs';
import { OurToastrService } from '../../../../shared/services/our.toastr.service';
import { TutorService } from '../../../../tutor/services/tutor.service';
import { TrnaslateService } from '../../../../utils/services/trnaslatee.service';
import * as _ from 'lodash';
import { environment } from '../../../../../environments/environment';
import { Sweetalert2Service } from '../../../../utils/services/sweetalert2.service';
import { IUser } from '../../../interface';
import { UserService } from '../../../services/user.service';
declare var localStorage: any;

@Component({
  selector: 'app-topic-create', templateUrl: '../form.html',
  styleUrls: [
    '../../../../../assets/quill/quill.core.css',
    '../../../../../assets/quill/quill.bubble.css',
    '../../../../../assets/quill/quill.snow.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TopicCreateComponent implements OnInit, OnDestroy {
  public maxFileSize: number;
  public topic: ITopic = {
    name: '',
    maximumStrength: 1,
    categoryIds: [],
    isOpen: false,
    isFree: false,
    price: 0,
    mediaIds: [],
    mainImageId: '',
    description: '',
    alias: '',
    gradeId: '',
    subjectId: '',
  };
  public categories: ICategory[] = [];
  public isSubmitted: Boolean = false;
  public mediaOptions: any;
  public mainImageOptions: any;
  public medias: any = [];
  public mainImageUrl: String = '';
  public Editor = ClassicEditor;
  public hashTopic: any;
  public topicId: any;
  public imageSelected: any[] = [];
  public filesSelected: any[] = [];
  public loading: boolean = false;

  grades = [];
  subjects = [];
  tab = 'basicInfo'
  public quillConfig = {
    magicUrl: true,
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block', 'link'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ font: [] }],
        [{ align: [] }],

        ['clean']
        // ['image']
      ]
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            return true;
          }
        }
      }
    }
  };
  public rtl: boolean = false;
  public langChange: Subscription;

  constructor(
    public userService: UserService,
    private router: Router,
    private topicService: TopicService,
    private toasty: OurToastrService,
    private categoryService: CategoryService,
    private calendarService: CalendarService,
    private tutorService: TutorService,
    private trnaslateService: TrnaslateService,
    private systemService: SystemService,
    private sweetalert: Sweetalert2Service,
    private authService: AuthService
  ) {
    this.maxFileSize = window['appConfig']?.maximumFileSizeImage;
    this.langChange = this.systemService.languageChanged$.subscribe(language => {
      this.rtl = this.systemService.checkRTL();
    });
  }

  ngOnInit() {
    this.rtl = this.systemService.checkRTL();
    this.getTutorGradesAndSubjects();
    this.checkDefaultMeetingUrl();
    this.mainImageOptions = {
      url: environment.apiBaseUrl + '/media/photos',
      fileFieldName: 'file',
      autoUpload: true,
      maxFileSize: environment.maximumFileSizeImage,

      onCompleteItem: resp => {
        this.topic.mainImageId = resp.data._id;
        this.mainImageUrl = resp.data.thumbUrl;
      },
      onFileSelect: resp => (this.imageSelected = resp),
      accept: 'image/*',
      id: 'image-upload'
    };
    this.mediaOptions = {
      url: environment.apiBaseUrl + '/media/files',
      fileFieldName: 'file',
      autoUpload: true,
      multiple: true,
      maxFileSize: environment.maximumFileSizeImage,

      onCompleteItem: resp => {
        this.topic.mediaIds.push(resp.data._id);
        this.medias.push(resp.data);
      },
      onFileSelect: resp => (this.filesSelected = resp),
      id: 'file-upload'
    };
    this.hashTopic = window['localStorage']?.getItem('hast_topic');
    if (!this.hashTopic) {
      this.hashTopic = randomHash(32, '');
      window['localStorage']?.setItem('hast_topic', this.hashTopic);
    }

    this.calendarService.deleteNotUsed();

  }

  ngOnDestroy() {


  }

  async checkDefaultMeetingUrl() {
    let currentUser = (await this.userService.me()).data;
    if (currentUser.defaultMeetingUrl && currentUser.defaultMeetingUrl != '')
      return
    let action = await this.sweetalert.confirmWarning("Tutor no Default Meeting Link alert", "Ok, go to profile", "No, back to dashboard")
    if (action.isConfirmed)
      return this.router.navigate(["/users", "profile"]);
    else
      return this.router.navigate(["/users", "dashboard"]);
  }

  getTutorGradesAndSubjects() {
    this.tutorService.getTutorGradesAndSubjects()
      .then(resp => {

        //Check If Current Tutor Not Have Subjects
        if (resp.data.subjects.length == 0) {

          this.sweetalert.confirmWarning("Tutor no subjects alert", "Ok, go to profile", "No, back to dashboard")
            .then(action => {
              if (action.isConfirmed)
                return this.router.navigate(["/users", "profile"]);
              else
                return this.router.navigate(["/users", "dashboard"]);
            });
          return
        }


        this.grades = resp.data.grades;
        this.subjects = resp.data.subjects;
        this.trnaslateService.appendLableOnItems(this, 'subjects');
        this.trnaslateService.appendLableOnItems(this, 'grades');



      })
      .catch(() => {
        this.loading = false;
        console.log('Something went wrong, please try again!');
      });
  }

  removeMedia(i: any) {
    this.topic.mediaIds.splice(i, 1);
    this.medias.splice(i, 1);
  }

  submit(frm: any) {
    if (this.topic._id)
      return this.submitUpdate(frm);

    this.isSubmitted = true;
    if (!frm.valid || !this.topic.description) {
      return this.toasty.error('Invalid form, please try again.');
    }
    if (this.topic.price <= 0 && !this.topic.isFree) {
      return this.toasty.error('Price value should be greater than 0');
    }

    if (!this.topic.mainImageId /*|| !this.topic.mediaIds*/)
      return this.toasty.error("Please upload all documents")

    if (this.topic.description) {
      this.topic.description = this.topic.description?.replace(
        '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
        ''
      );
    }


    this.calendarService.checkByHash(this.hashTopic).then(check => {
      if (!check.data.success) {
        return this.toasty.error('Please create schedule for webinar');
      }
      const data = this.hashTopic ? Object.assign(this.topic, { hashTopic: this.hashTopic }) : this.topic;
      this.topicService.create(data).then(
        resp => {
          window['localStorage']?.removeItem('hast_topic');
          this.toasty.success('Topic has been created');
          this.topic.tutor = { _id: resp.data.tutorId } as IUser;
          this.topic.tutorId = resp.data.tutorId;

          this.topic._id = resp.data._id;
          this.topicId = this.topic._id;
          //  this.router.navigate(['/users/topics/' + resp.data._id]);
        },
        err => this.toasty.error(err.data.message || 'Something went wrong!')
      );
    });
  }

  submitUpdate(frm: any) {
    this.isSubmitted = true;
    if (!frm.valid || !this.topic.description) {
      return this.toasty.error('Invalid form, please try again.');
    }
    if (this.topic.price <= 0 && !this.topic.isFree) {
      return this.toasty.error('Price value should be greater than 0');
    }
    if (!this.topic.mainImageId || !this.topic.mediaIds)
      return this.toasty.error("Please upload all documents")

    if (this.topic.description) {
      this.topic.description = this.topic.description?.replace(
        '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
        ''
      );
    }
    this.loading = true;
    this.calendarService.checkByTopic(this.topicId).then(check => {
      if (!check.data.success) {
        this.loading = false;
        return this.toasty.error('Please create schedule for webinar if you want the webinar to be public');
      }
      this.topic.isOpen = true;
      this.topicService
        .update(
          this.topicId,
          _.pick(this.topic, [
            'name',
            'maximumStrength',
            'categoryIds',
            'isOpen',
            'isFree',
            'price',
            'gradeId',
            'subjectId',
            'mediaIds',
            'mainImageId',
            'description',
            'alias'
          ])
        )
        .then(resp => {
          this.toasty.success('Updated successfuly!');
          // this.router.navigate(['/users/topics']);
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.toasty.error((err.data.data && err.data.data.message) || err.data.message || err.data.email);
        });
    });
  }

  onTabSelect(tab: string) {
    this.tab = tab;
  }
}
